import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  ActionsContainer,
  DocumentSelectModalComponent,
  ErrorContainer,
  FilesContainer,
  InfoContainer,
  InfoLeftContainer,
  ModalContent,
  PreviewContainer,
  WarningContainer,
} from './DocumentSelectModal.styles';

import { DocumentSelectModalTypes, SelectableDocument } from './types';
import { Modal } from 'components/molecules';
import { Button, Icon, Typography } from 'components/atoms';

import DocumentCard from 'components/molecules/document-card';
import { FileFormat } from 'helpers/documentHelper';
import { ReactComponent as InfoIcon } from './../../../assets/svg/Icons/InfoToast.svg';
import { useTheme } from 'styled-components';

const DocumentSelectModal: FC<DocumentSelectModalTypes> = ({
  isOpen,
  onClose,
  onAttachDocuments,
  maxUploads,
  selectableDocuments,
}: DocumentSelectModalTypes) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [documents, setDocuments] = useState<SelectableDocument[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectionError, setSelectionError] = useState('');

  const handleCloseOrCancel = () => {
    resetDocumentsSelectedState();
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    setDocuments([...selectableDocuments]);
    // eslint-disable-next-line
  }, [modalOpen]);

  const onSelectionChanged = useCallback(
    (selectedState, id) => {
      const updatedDocumentsState: SelectableDocument[] = documents.map(
        (document) => {
          if (document.id === id) {
            return { ...document, selected: selectedState };
          }
          return document;
        }
      );
      setDocuments(updatedDocumentsState);
    },
    [documents]
  );

  const resetDocumentsSelectedState = () => {
    const updatedDocumentsState: SelectableDocument[] = documents.map(
      (document) => {
        return { ...document, selected: false };
      }
    );
    setDocuments(updatedDocumentsState);
  };

  const getSelectedDocuments = useCallback((): SelectableDocument[] => {
    return documents.filter((f) => f.selected);
  }, [documents]);

  const handleAttachedDocuments = () => {
    const selectedDocuments = getSelectedDocuments();
    onAttachDocuments(selectedDocuments);
    resetAllState();
  };

  const resetAllState = () => {
    resetDocumentsSelectedState();
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    setSelectedCount(getSelectedDocuments().length);
    // eslint-disable-next-line
  }, [documents]);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (maxUploads && selectedCount > maxUploads) {
      setSelectionError(
        'You have reached the maximum number of documents you can attach to this category'
      );
    } else {
      setSelectionError('');
    }
    // eslint-disable-next-line
  }, [selectedCount]);

  return (
    <DocumentSelectModalComponent>
      <Modal
        removePadding
        isOpen={modalOpen}
        onClose={() => handleCloseOrCancel()}
        title={'Uploaded documents'}
      >
        <ModalContent>
          <InfoContainer>
            <InfoLeftContainer>
              <Typography
                className="record-text"
                value={`${documents.length} records`}
                tag="h6"
              />
              <span className="dot"> &#8226;</span>
              <Typography value={`${selectedCount} selected`} tag="h6" />
            </InfoLeftContainer>
            <span onClick={resetDocumentsSelectedState}>
              <Typography
                className="cancel-text"
                value={`Deselect all`}
                tag="h6"
              />
            </span>
          </InfoContainer>
          <WarningContainer>
            <InfoIcon />
            <div className="messageContainer">
              <p className="message">
                If the desired document does not appear below, it may either not
                belong to the specified document category, lacks a document
                verification date, or does not align with the requested date
                criteria.
              </p>
            </div>
          </WarningContainer>

          <FilesContainer>
            {documents.map((selectableDocument: SelectableDocument) => {
              return (
                <DocumentCard
                  key={selectableDocument.id}
                  fileType={selectableDocument.type.toLowerCase() as FileFormat}
                  date={selectableDocument.uploadedDate}
                  name={selectableDocument.name}
                  category={selectableDocument.category}
                  option="withSelect"
                  onSelect={(isSelected) => {
                    if (isSelected && selectedCount == maxUploads) {
                      setSelectionError(
                        'You have reached the maximum number of documents you can attach to this category'
                      );
                      return;
                    }
                    onSelectionChanged(isSelected, selectableDocument.id);
                  }}
                  isSelected={selectableDocument.selected}
                  tapSelect
                />
              );
            })}
          </FilesContainer>
          {selectionError && (
            <PreviewContainer>
              <ErrorContainer>
                <Icon
                  className="risk-icon"
                  icon="risk"
                  size="xxs"
                  colour={theme.colours.redRampage}
                />
                <Typography value={selectionError} tag="p" />
              </ErrorContainer>
            </PreviewContainer>
          )}
          <ActionsContainer>
            <Button
              data-testid="DocumentSelectModalCancelButton"
              onClick={() => handleCloseOrCancel()}
              text
            >
              Cancel
            </Button>
            <Button
              data-testid="DocumentSelectModalAttachButton"
              disabled={selectedCount <= 0}
              onClick={() => handleAttachedDocuments()}
              className="attach-button"
              secondary
            >
              Attach
            </Button>
          </ActionsContainer>
        </ModalContent>
      </Modal>
    </DocumentSelectModalComponent>
  );
};

export default DocumentSelectModal;
