import { Tab } from 'components/atoms';
import React, { FC, ReactElement } from 'react';
import { CTAContainer, CtaSlot, TabContainer } from './Tabs.styles';

import { TabContent, TabsTypes } from './types';

const Tabs: FC<TabsTypes> = ({
  children,
  ctas,
  selected,
  setSelected,
  dark,
}: TabsTypes) => {
  if (!children) {
    return null;
  }
  if (!Array.isArray(children)) {
    children = [children];
  }

  children = children.filter((child) => child);
  return (
    <>
      <TabContainer dark={dark}>
        {children.map((child: TabContent, i: number) => (
          <Tab
            key={child?.props?.label || i}
            label={child?.props?.label ?? i.toString()}
            onClick={() => setSelected(i)}
            active={selected === i}
            dark={dark}
          />
        ))}
        <CtaSlot>
          {ctas &&
            ctas.map(
              (child: { i: Array<number>; cta: ReactElement }) =>
                child?.i.indexOf(selected) > -1 && (
                  <CTAContainer key={child.cta.key}>{child.cta}</CTAContainer>
                )
            )}
        </CtaSlot>
      </TabContainer>
      {children.map((child, i) => i === selected && child)}
    </>
  );
};

export default Tabs;
