// Names from https://colornamer.robertcooper.me

export const colours = {
  white: '#ffffff',
  black: '#000000',
  darkToneInk: '#121212',
  superSilver: '#eeeeee',
  gallery: '#ececec',
  spanishGrey: '#d8d8d8',
  whiteSolid: '#f2f5fa',
  sparklingSnow: '#e6f8f6',
  agedGray: '#7d7f80',
  dustyGray: '#979797',
  silverSand: '#CACDCD',
  concreteGray: '#cacdcd',
  lightBlueGray: '#ebf0f1',
  charcoalDust: '#595858',
  crowberryBlue: '#053747',
  swimmersPool: '#22c3bb',
  porpoisePlace: '#0c687d',
  pacificBlue: '#059dc1',
  deepWater: '#226CA5',
  indianRed: '#e94f5f',
  redRampage: '#ef3826',
  spicedPurple: '#b90a67',
  fluorescentOrange: '#ffce00',
  paleBlueLily: '#c9ebed',
  carbonFiber: '#2e2e2e',
  eerieBlack: '#1a1a1a',
  farsighted: '#e4e4ef',
  // low, medium, high risk colours.
  arcadianGreen: '#9dca8d',
  californiaDreaming: '#dcc769',
  deepCoral: '#db7b59',
  poisonIvy: '#00ac43',
  friendlyFrost: '#bbfbfb',
  // unknown, low, high risk colours
  aquaSphere: '#9bafb5',
  limeRasp: '#afcb08',
  desire: '#e83c4e',
  // toast colours
  toastInfoAccent: '#023F6F',
  toastInfoBg: '#E4EDF4',
  toastErrorAccent: '#B90F20',
  toastErrorBg: '#FCE7E9',
  toastWarnAccent: '#621389',
  toastWarnBg: '#EFE9F2',
  toastSuccessAccent: '#005047',
  toastSuccessBg: '#E0F0EE',
};
