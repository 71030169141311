import React, { createContext, useContext, useMemo } from 'react';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';
import LoadingContainer from 'context/Loading';
import { Country } from './types';

export const CountriesContext = createContext([]);

export const CountriesProvider = ({ children }) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const {
    data: countriesData,
    loading,
    error,
  } = useFetch<Country[]>(`${vantageWebApi}/client/countries`, {
    initialState: { loading: !!accessToken },
    lazy: !accessToken,
    method: 'GET',
    headers,
  });

  if (error) {
    return;
  }
  return (
    <CountriesContext.Provider value={countriesData}>
      {loading ? <LoadingContainer error={error} /> : children}
    </CountriesContext.Provider>
  );
};

/**
 * @function useCountries
 * @description Custom hook for accessing the countries data context.
 * Facilitates the reuse of the context in components and avoids repeated imports of useContext.
 * @returns An array of countries with their respective alpha2Code.
 */
export const useCountries = () => useContext(CountriesContext);
