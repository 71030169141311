import React, { FC, useState } from 'react';

import { ProfileDetailsTypes } from './types';
import { Date } from 'components/atoms';
import ProfileModal from 'components/organisms/profile-modal';
import ScreeningDetails from '../screening-details';

const ProfileDetails: FC<ProfileDetailsTypes> = ({
  details,
  screeningType,
}: ProfileDetailsTypes) => {
  const [modalOpen, setModalOpen] = useState(false);
  const data = [
    { label: 'Primary name: ', value: details?.primaryName },
    { label: 'Matched name: ', value: details?.matchedName },
    {
      label: 'Reason listed: ',
      value: details?.matchReasonDetails,
    },
    { label: 'Summary of inclusion: ', value: details?.summaryOfInclusion },
    { label: 'Profile ID: ', value: details?.profileId },
    {
      label: 'Primary country: ',
      value: details?.countries ? details.countries.split(', ')?.[0] : null,
    },
    {
      label: 'Date matched: ',
      value: <Date dateTime={details?.profileMatchedDate} />,
    },
    {
      label: 'Last updated: ',
      value: <Date dateTime={details?.profileLastUpdatedDate} />,
    },
  ];
  return (
    <>
      <ScreeningDetails
        heading="Profile"
        subHeading="Details: "
        details={data}
        ctaLabel="View full profile"
        ctaClick={() => setModalOpen(true)}
      />
      <ProfileModal
        screeningType={screeningType}
        matchData={details}
        onClose={() => setModalOpen(false)}
        isOpen={modalOpen}
      />
    </>
  );
};

export default ProfileDetails;
