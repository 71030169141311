export const auditEvents = [
  'Third Party - Created',
  'Third Party - Modified',
  'Third Party - Next review date applied',
  'Third Party - Approval status changed',
  'Third Party - Screening initiated',
  'Third Party - Monitoring settings modified ',
  'Third Party - Review finalised',
  'Third Party - Monitoring alert(s) received',
  'Third Party - Client monitoring settings modified',
  'Third Party - Review status modified',
  'Third Party - Screening type converted',
  'Third Party - Report ready for review',
  'Third Party - Updated report ready for review',
  'Third Party - Due Diligence report ordered',
  'Related Entity - Created',
  'Related Entity - Modified',
  'Related Entity - Deleted',
  'Related Entity - Screening initiated',
  'Related Entity - Monitoring settings modified ',
  'Related Entity - Review finalised',
  'Related Entity - Monitoring alert(s) received',
  'Related Entity - Review status modified ',
  'Related Entity - Screening type converted ',
  'Related Entity - Screening report ordered',
  'Related Entity - Report ready for review',
  'Related Entity - Updated report ready for review',
];
