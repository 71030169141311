import styled from 'styled-components';
import { MessageProps } from './types';

export const MessageComponent = styled.div<
  Pick<MessageProps, 'dark' | 'highlighted' | 'large'>
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ large = false, theme }) => theme.spacing(large ? 42 : 20)};
  border: 1px solid
    ${({ dark = false, theme }) =>
      dark ? theme.colours.crowberryBlue : theme.colours.white};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  background-color: ${({ dark = false, highlighted = false, theme }) =>
    dark
      ? theme.colours.crowberryBlue
      : highlighted
      ? theme.colours.fluorescentOrange
      : theme.colours.whiteSolid};
  font-size: ${({ large = false, theme }) => theme.pxToRem(large ? 24 : 16)};

  svg {
    flex: 1 0 auto;
    color: ${({ dark = false, theme }) =>
      dark ? theme.colours.swimmersPool : theme.colours.indianRed};
    transform: translateY(
      ${({ large = false, theme }) => theme.pxToRem(large ? 4 : 2)}
    );
  }

  svg,
  > a {
    flex: 0 0 auto;
  }

  > a {
    margin-left: ${({ theme }) => theme.spacing(30)};
  }

  p {
    color: ${({ dark = false, highlighted = false, theme }) =>
      dark
        ? theme.colours.white
        : highlighted
        ? theme.colours.black
        : theme.colours.charcoalDust};
  }
`;

export const MessageMain = styled.div`
  display: flex;
  flex-grow: 0 1 0;
  align-items: first baseline;
  font-size: inherit;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(15)};
  }
`;
