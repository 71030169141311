import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Container,
  Button,
  ContextMenu,
  ContextMenuItem,
  ButtonWrapper,
  ContextTitleContainer,
  UploadContainer,
  FilesContainer,
  CategoryTextContainer,
} from './MultipleUploadOption.styles';
import { ReactComponent as StackSvg } from 'assets/svg/Icons/Stack.svg';
import { ReactComponent as SystemSvg } from 'assets/svg/Icons/System.svg';

import { MultipleUploadOptionTypes } from './types';
import DocumentCard from '../document-card';

import { FileFormat } from 'helpers/documentHelper';
import Icon from 'components/atoms/icon';

const MultipleUploadOption: FC<MultipleUploadOptionTypes> = ({
  label,
  required,
  maxUploads,
  onExistingDocumentUpload,
  showSidebar = false,
  onNewDocumentUpload,
  documents,
  onRemoveDocument,
  valid,
  showExistingDocumentsOption,
}: MultipleUploadOptionTypes) => {
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);

  const handleContextMenuClick = () => {
    setContextMenuOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextRef.current &&
        !contextRef.current.contains(event.target as Node)
      ) {
        setContextMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <UploadContainer>
        <CategoryTextContainer required={required} valid={valid}>
          <p className="category-text">
            {label}
            <span className="required">*</span>
          </p>
          {maxUploads ? (
            <p className="limit-text">Up to {maxUploads} files</p>
          ) : null}
        </CategoryTextContainer>
        <ButtonWrapper>
          <Button
            disabled={maxUploads && documents.length >= maxUploads}
            onClick={handleContextMenuClick}
            type="button"
            aria-label={label}
          >
            <Icon
              icon="upload"
              size="xxs"
              className="upload-icon"
              aria-label="upload-icon"
            />
            Upload document
          </Button>
          {isContextMenuOpen && (
            <ContextMenu data-testid="ContextMenu" ref={contextRef}>
              <ContextTitleContainer>
                <p className="text">From</p>
              </ContextTitleContainer>
              {showExistingDocumentsOption && (
                <ContextMenuItem
                  data-testid="ExistingDocumentsOption"
                  onClick={() => {
                    onExistingDocumentUpload();
                    setContextMenuOpen(false);
                  }}
                >
                  <StackSvg className="icon" />
                  <p className="text">Existing documents</p>
                </ContextMenuItem>
              )}
              <ContextMenuItem
                data-testid="NewDocumentsOption"
                onClick={() => {
                  onNewDocumentUpload();
                  setContextMenuOpen(false);
                }}
              >
                <SystemSvg className="icon" />
                <p className="text">Your local device</p>
              </ContextMenuItem>
            </ContextMenu>
          )}
        </ButtonWrapper>
      </UploadContainer>
      <FilesContainer showSidebar={showSidebar}>
        {documents.map((fileInfo, index) => {
          return (
            <DocumentCard
              key={index}
              name={fileInfo.name}
              fileType={fileInfo.type?.toLowerCase() as FileFormat}
              date={fileInfo.uploadedDate ?? new Date().toString()}
              category={fileInfo.category}
              option="withRemove"
              onOptionClick={() => onRemoveDocument(fileInfo.id)}
            />
          );
        })}
      </FilesContainer>
    </Container>
  );
};

export default MultipleUploadOption;
