import { useMemo } from 'react';
import { useCountries } from 'context/countries';

export function useCountryHelper() {
  const countriesData = useCountries();

  const { nameMap, codeMap } = useMemo(() => {
    if (countriesData) {
      const nameMap = {};
      const codeMap = {};

      countriesData.forEach((country) => {
        nameMap[country.name.toLowerCase()] = country.code;
        codeMap[country.code.toLowerCase()] = country.name;
      });

      return { nameMap, codeMap };
    }
    return { nameMap: {}, codeMap: {} };
  }, [countriesData]);

  const sortedCountries = useMemo(() => {
    return countriesData
      ? countriesData
          .map(({ name, code }) => ({
            name,
            value: code,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : [];
  }, [countriesData]);

  const getCode = (name) => {
    if (!name) return null;
    return nameMap[name.toLowerCase()] || null;
  };

  const getName = (code) => {
    if (!code) return null;
    return codeMap[code.toLowerCase()] || null;
  };

  const getNames = () => countriesData.map((country) => country.name);

  const getCodes = () => countriesData.map((country) => country.code);

  const getCodeList = () => codeMap;

  const getNameList = () => nameMap;

  const getData = () => countriesData;

  return {
    getCode,
    getName,
    getNames,
    getCodes,
    getCodeList,
    getNameList,
    getData,
    sortedCountries,
  };
}
