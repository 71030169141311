import {
  ConfiguredDocument,
  DocumentsConfig,
  SelectedRelatedEntity,
} from 'pages/initiate-due-diligence/types';
import { isVerificationDateValid } from './verificationDateHelper';
import { ThirdPartyType } from 'types/thirdPartyType';
import { CreateErrorOptions, ValidationError } from 'yup';
import dayjs from 'dayjs';
export interface Document {
  id: string;
  name: string;
  category: string;
  categoryId: number;
  size: number;
  type: string;
  date: string;
  verificationDate: string | null;
  isOptionalDocument?: boolean;
}

/**
 * Document type from Backend
 */
export interface FileInformationResponse {
  id: string;
  name: string;
  fileDownloadName: string;
  category: string;
  categoryId: number;
  size: number;
  type: string;
  thirdPartyId: number;
  verificationDate: string;
  uploadedBy: string;
  uploadedDate: string;
}

/**
 * Document type for Cards
 */
export interface FileInfo {
  id: string;
  name: string;
  category: string;
  categoryId: number;
  type: string;
  uploadedDate: string;
  verificationDate: string | null;
  size: number;
  uploadedBy: string;
  fileDownloadName: string;
  extension: string;
  isChecked?: boolean;
}

/**
 * Document type in DocumentsData form field
 */
export interface DocumentData {
  name: string;
  size: number;
  type: string;
  category: string;
  categoryId: number;
  entityId?: number;
  thirdPartyId?: number;
  verificationDate: string | null;
  id: string;
  fileDownloadName: string;
  uploadedBy: string;
  uploadedDate: string;
  isOptionalDocument: boolean;
  isRelatedEntityDocument: boolean;
}

export enum FileFormat {
  PNG = 'png',
  JPEG = 'jpeg',
  JPG = 'jpg',
  CSV = 'csv',
  PDF = 'pdf',
  DOCX = 'docx',
  XLSX = 'xlsx',
  ZIP = 'zip',
}

export const fileInformationResponseToDocumentData = (
  fileInformationResponse: FileInformationResponse,
  isRelatedEntityDocument: boolean | undefined = undefined,
  entityId: number | undefined = undefined,
  isOptional: boolean | undefined = undefined
) => {
  const documentData: DocumentData = {
    name: fileInformationResponse.name,
    size: fileInformationResponse.size,
    type: getFileExtension(fileInformationResponse.fileDownloadName),
    category: fileInformationResponse.category,
    categoryId: fileInformationResponse.categoryId,
    entityId: entityId,
    thirdPartyId: fileInformationResponse.thirdPartyId,
    verificationDate: fileInformationResponse.verificationDate,

    id: fileInformationResponse.id,
    fileDownloadName: fileInformationResponse.fileDownloadName,
    uploadedBy: fileInformationResponse.uploadedBy,
    uploadedDate: fileInformationResponse.uploadedDate,
    isOptionalDocument: isOptional ?? false,
    isRelatedEntityDocument: isRelatedEntityDocument ?? false,
  };

  return documentData;
};

export const calculateFileSize = (size: number): string => {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getFileExtension = (filename: string): string => {
  const regex = /(?:\.([^.]+))?$/;
  const match = regex.exec(filename);
  if (match) {
    return match[1];
  }
  return '';
};

/**
 * delete verificationDateConfig when verificationDate is disabled
 * */
export const normalizeVerificationDateConfigs = (
  documentsConfigRaw: DocumentsConfig
) => {
  documentsConfigRaw.thirdPartyDocuments.thirdPartyConfiguredDocumentsIndividual =
    documentsConfigRaw.thirdPartyDocuments.thirdPartyConfiguredDocumentsIndividual.map(
      (configuredDoc) => ({
        ...configuredDoc,
        verificationDateConfig: configuredDoc.verificationDateEnabled
          ? configuredDoc.verificationDateConfig
          : null,
      })
    );

  documentsConfigRaw.thirdPartyDocuments.thirdPartyConfiguredDocumentsBusiness =
    documentsConfigRaw.thirdPartyDocuments.thirdPartyConfiguredDocumentsBusiness.map(
      (configuredDoc) => ({
        ...configuredDoc,
        verificationDateConfig: configuredDoc.verificationDateEnabled
          ? configuredDoc.verificationDateConfig
          : null,
      })
    );

  documentsConfigRaw.relatedEntitiesDocuments.relatedEntitiesConfiguredDocumentsIndividual =
    documentsConfigRaw.relatedEntitiesDocuments.relatedEntitiesConfiguredDocumentsIndividual.map(
      (configuredDoc) => ({
        ...configuredDoc,
        verificationDateConfig: configuredDoc.verificationDateEnabled
          ? configuredDoc.verificationDateConfig
          : null,
      })
    );

  documentsConfigRaw.relatedEntitiesDocuments.relatedEntitiesConfiguredDocumentsBusiness =
    documentsConfigRaw.relatedEntitiesDocuments.relatedEntitiesConfiguredDocumentsBusiness.map(
      (configuredDoc) => ({
        ...configuredDoc,
        verificationDateConfig: configuredDoc.verificationDateEnabled
          ? configuredDoc.verificationDateConfig
          : null,
      })
    );

  return documentsConfigRaw;
};

export const isIndividualRelatedEntityDocumentRequiredDependingOnAge = (
  relatedEntity: SelectedRelatedEntity | undefined,
  configuredDoc: ConfiguredDocument
): boolean => {
  if (!relatedEntity) {
    return false;
  }

  if (!relatedEntity.dateOfBirth) {
    return true;
  }

  const age = dayjs().diff(dayjs(relatedEntity.dateOfBirth), 'year');
  return age >= configuredDoc.ageOptionalDocOverride;
};

export const validateRequiredDocumentData = (
  errorMessage: string,
  documents: DocumentData[],
  documentsConfig: DocumentsConfig,
  thirdPartyType: ThirdPartyType,
  selectedRelatedEntities: SelectedRelatedEntity[],
  createError: (params?: CreateErrorOptions) => ValidationError
) => {
  if (!documentsConfig) {
    return createError({
      message: errorMessage,
      type: `required-documentsConfig`,
    });
  }

  if (documentsConfig.isRequiredDocumentsForThirdParty) {
    const thirdPartyDocs = documents.filter(
      (doc) => !doc.isRelatedEntityDocument && !doc.isOptionalDocument
    );

    if (thirdPartyType == ThirdPartyType.Business) {
      for (const configuredDoc of documentsConfig.thirdPartyDocuments
        .thirdPartyConfiguredDocumentsBusiness) {
        const foundDoc = thirdPartyDocs.find(
          (doc) => doc.categoryId === configuredDoc.categoryId
        );

        if (!foundDoc) {
          return createError({
            message: errorMessage,
            type: `required-documentsData-${configuredDoc.categoryId}`,
          });
        }
      }
    } else {
      for (const configuredDoc of documentsConfig.thirdPartyDocuments
        .thirdPartyConfiguredDocumentsIndividual) {
        const foundDoc = thirdPartyDocs.find(
          (doc) => doc.categoryId === configuredDoc.categoryId
        );

        if (!foundDoc) {
          return createError({
            message: errorMessage,
            type: `required-documentsData-${configuredDoc.categoryId}`,
          });
        }
      }
    }
  }

  if (documentsConfig.isRequiredDocumentsForRelatedEntities) {
    const relatedEntitiesDocs = documents.filter(
      (doc) => doc.isRelatedEntityDocument
    );

    for (const relatedEntity of selectedRelatedEntities) {
      if (relatedEntity.type == ThirdPartyType.Business) {
        for (const configuredDoc of documentsConfig.relatedEntitiesDocuments
          .relatedEntitiesConfiguredDocumentsBusiness) {
          const foundDoc = relatedEntitiesDocs.find(
            (doc) => doc.categoryId === configuredDoc.categoryId
          );

          if (!foundDoc) {
            return createError({
              message: errorMessage,
              type: `required-documentsData-${configuredDoc.categoryId}`,
            });
          }
        }
      } else {
        for (const configuredDoc of documentsConfig.relatedEntitiesDocuments
          .relatedEntitiesConfiguredDocumentsIndividual) {
          if (
            !isIndividualRelatedEntityDocumentRequiredDependingOnAge(
              relatedEntity,
              configuredDoc
            )
          ) {
            return true;
          }

          const foundDoc = relatedEntitiesDocs.find(
            (doc) => doc.categoryId === configuredDoc.categoryId
          );

          if (!foundDoc) {
            return createError({
              message: errorMessage,
              type: `required-documentsData-${configuredDoc.categoryId}`,
            });
          }
        }
      }
    }
  }

  return true;
};

export const validateNoDuplicatedDocumentData = (
  errorMessage: string,
  documents: DocumentData[],
  createError: (params?: CreateErrorOptions) => ValidationError
) => {
  const uniqueDocuments = new Set();

  for (const doc of documents) {
    const docName = doc.name;
    if (uniqueDocuments.has(docName)) {
      return createError({
        message: errorMessage,
        type: `uniqueName-documentsData-${doc.categoryId}-${
          doc.isOptionalDocument ? 'optional' : ''
        }`,
      });
    } else {
      uniqueDocuments.add(docName);
    }
  }
  return true;
};

const validateAgainstVerificationDateConfig = (
  config: ConfiguredDocument,
  doc: DocumentData
) => {
  if (!config?.verificationDateEnabled) {
    return true;
  }

  if (!doc.verificationDate) {
    return false;
  }

  return isVerificationDateValid(
    config.verificationDateConfig,
    doc.verificationDate
  );
};

export const validateVerificationDateDocumentData = (
  errorMessage: string,
  documents: DocumentData[],
  documentsConfig: DocumentsConfig,
  createError: (params?: CreateErrorOptions) => ValidationError
) => {
  if (!documentsConfig) {
    return createError({
      message: errorMessage,
      type: `required-documentsConfig`,
    });
  }

  const thirdPartyConfiguredDocs = [
    ...documentsConfig.thirdPartyDocuments
      .thirdPartyConfiguredDocumentsIndividual,
    ...documentsConfig.thirdPartyDocuments
      .thirdPartyConfiguredDocumentsBusiness,
  ];

  const relatedEntitiesConfiguredDocs = [
    ...documentsConfig.relatedEntitiesDocuments
      .relatedEntitiesConfiguredDocumentsIndividual,
    ...documentsConfig.relatedEntitiesDocuments
      .relatedEntitiesConfiguredDocumentsBusiness,
  ];

  const thirdPartyDocs = documents.filter(
    (doc) => !doc.isRelatedEntityDocument && !doc.isOptionalDocument
  );

  const relatedEntitiesDocs = documents.filter(
    (doc) => doc.isRelatedEntityDocument
  );

  for (const doc of thirdPartyDocs) {
    const config = thirdPartyConfiguredDocs.find(
      ({ categoryId }) => categoryId === doc.categoryId
    );

    if (!validateAgainstVerificationDateConfig(config, doc)) {
      return createError({
        message: errorMessage,
        type: `verificationDate-documentsData-${config.categoryId}
          }`,
      });
    }
  }

  for (const doc of relatedEntitiesDocs) {
    const config = relatedEntitiesConfiguredDocs.find(
      ({ categoryId }) => categoryId === doc.categoryId
    );

    if (!validateAgainstVerificationDateConfig(config, doc)) {
      return createError({
        message: errorMessage,
        type: `verificationDate-documentsData-${config.categoryId}
          }`,
      });
    }
  }

  return true;
};
