import React, { useEffect, useRef } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'services/applicationInsightsService';
import { useParams } from 'react-router';
import { useFetch } from 'hooks';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';

import { AppLayout } from 'layouts';
import { Loading, Link } from 'components/atoms';
import { Redirect } from 'react-router-dom';

type ReportURLParams = {
  id: string;
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const ReportHtml = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const reportContainer = useRef<HTMLDivElement>(null);
  const { id } = useParams<ReportURLParams>();
  const { data, loading, error, responseStatus } = useFetch(
    `${vantageWebApi}/onlinereport/${id}/html`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    'text'
  );

  useEffect(() => {
    if (!data || isJsonString(data) || data?.status == 404) {
      return;
    }

    // Place the report markup inside a shadow root to encapsulate style.
    const shadowRoot = reportContainer.current.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = data;
  }, [data]);

  if (responseStatus == 404) {
    return <Redirect to="/not-found" />;
  }

  if (responseStatus === 401) {
    return <Redirect to="/unauthorised" />;
  }

  return (
    <AppLayout
      topBarSlot={
        <Link to={`/third-party/screening/${id}`} dark>
          Back to {terminologyConfig?.thirdPartySingularNaming} manager
        </Link>
      }
    >
      {loading ? (
        <Loading />
      ) : error || (responseStatus && responseStatus !== 200) ? (
        <p>Error loading report</p>
      ) : (
        <div ref={reportContainer}></div>
      )}
    </AppLayout>
  );
};

export default withAITracking(reactPlugin, ReportHtml);
