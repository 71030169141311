import React, { useContext } from 'react';
import { Button, FieldDate, FieldSelect } from 'components/atoms';
import { RelatedEntityModalContext } from '../../Context';
import FormControl from 'components/atoms/form-control';
import Input from 'components/atoms/field-input';
import Select from 'components/atoms/field-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { ButtonWrapper, GridFormContainer, GridRow } from '../index.styles';
import { Gender } from '../../../../../types/genders';
import { ThirdPartyType } from 'types/thirdPartyType';
import dayjs from 'dayjs';
import { RelatedEntityModalFormValues } from '../../types';
import { useCountryHelper } from 'helpers/countriesHelper';

type StepTwo = {
  relatedEntity: any;
  isIndividualDateOfBirthMandatory: boolean;
  onBackClick?: () => void;
  onSubmit?: (relatedEntity: RelatedEntityModalFormValues) => void;
  performScreening?: boolean;
};

export const createSchema = (
  isIndividualDateOfBirthMandatory: boolean,
  itsIndividualRelatedEntity: boolean
) => {
  return yup.object().shape({
    addressLineOne: yup.string().max(140, 'Maximum 140 characters').nullable(),
    city: yup.string().max(60, 'Maximum 60 characters').nullable(),
    county: yup.string().max(60, 'Maximum 60 characters').nullable(),
    zipCode: yup.string().max(10, 'Maximum 10 characters').nullable(),
    passportNumber: yup.string().max(20, 'Maximum 20 characters').nullable(),
    nationalIDNumber: yup.string().max(20, 'Maximum 20 characters').nullable(),
    otherIDNumber: yup.string().max(20, 'Maximum 20 characters').nullable(),
    dateOfBirth: yup
      .date()
      .nullable()
      .when([], {
        is: () =>
          itsIndividualRelatedEntity && isIndividualDateOfBirthMandatory,
        then: (schema) => schema.required(`Please enter a date of birth`),
      }),
    registrationNumber: yup
      .string()
      .max(25, 'Maximum 25 characters')
      .nullable(),
    swift: yup.string().max(20, 'Maximum 20 characters').nullable(),
    duns: yup
      .string()
      .max(19, 'Maximum 19 characters')
      .nullable()
      .test('duns-number', 'Duns needs to be a number', (value) => {
        if (itsIndividualRelatedEntity || !value) {
          return true;
        }
        const Regex = new RegExp(/^[0-9]*$/);
        return Regex.test(value);
      }),
  });
};

const StepTwo: React.FC<StepTwo> = ({
  relatedEntity,
  isIndividualDateOfBirthMandatory,
  onBackClick,
  onSubmit: onExternalSubmit,
}) => {
  const { state: formState } = useContext(RelatedEntityModalContext);

  const defaultValues = {
    ...relatedEntity,
    ...formState,
    idType: 'DEFAULT',
    countryTwo: formState?.countryTwo ?? null,
    countryThree: formState?.countryThree ?? null,
    nationality: formState?.nationality ?? 'DEFAULT',
    // duns: formState?.duns ?? null,
    gender:
      formState?.gender?.toString() || relatedEntity?.gender?.toString() || '2',
    dateOfBirth:
      formState?.dateOfBirth || relatedEntity?.dateOfBirth || undefined,
  };

  if (formState?.dateOfBirth) {
    defaultValues.dateOfBirth = new Date(formState?.dateOfBirth);
  }

  const itsIndividualRelatedEntity =
    formState?.type === ThirdPartyType.Individual;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      createSchema(isIndividualDateOfBirthMandatory, itsIndividualRelatedEntity)
    ),
    defaultValues: defaultValues,
  });

  const { sortedCountries: countries } = useCountryHelper();

  const genderOptions = Object.keys(Gender).map((key) => ({
    name: key,
    value: Gender[key],
  }));

  const onSubmit = (data: RelatedEntityModalFormValues) => {
    if (onExternalSubmit) {
      onExternalSubmit({ ...formState, ...data });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridFormContainer>
        <GridRow>
          <FormControl errorMessage={errors?.addressLineOne?.message}>
            <Input
              label="Address"
              id="addressLineOne"
              name="addressLineOne"
              register={register}
              fullWidth
            />
          </FormControl>
          <FormControl errorMessage={errors?.city?.message}>
            <Input
              label="City"
              id="city"
              name="city"
              register={register}
              fullWidth
            />
          </FormControl>
          <FormControl errorMessage={errors?.county?.message}>
            <Input
              label="State/region/province"
              id="county"
              name="county"
              register={register}
              fullWidth
            />
          </FormControl>
          <FormControl errorMessage={errors?.zipCode?.message}>
            <Input
              label="Postcode/Zip Code"
              id="zipCode"
              name="zipCode"
              register={register}
              fullWidth
            />
          </FormControl>
          <FormControl errorMessage={errors?.countryTwo?.message}>
            <FieldSelect
              valid={!errors?.countryTwo}
              id="countryTwo"
              placeholder="Please Select"
              placeholderValue="DEFAULT"
              label="Country 2"
              options={countries}
              register={register}
              fullWidth
            ></FieldSelect>
          </FormControl>
          {itsIndividualRelatedEntity && (
            <FormControl errorMessage={errors?.countryThree?.message}>
              <FieldSelect
                valid={!errors?.countryThree}
                id="countryThree"
                placeholder="Please Select"
                placeholderValue="DEFAULT"
                label="Country 3"
                options={countries}
                register={register}
                fullWidth
              ></FieldSelect>
            </FormControl>
          )}
        </GridRow>
        {itsIndividualRelatedEntity && (
          <>
            <GridRow>
              <FormControl errorMessage={errors.gender?.message}>
                <FieldSelect
                  valid={!errors?.gender?.message}
                  id="gender"
                  placeholder="Please Select"
                  placeholderValue="2"
                  label="Gender"
                  options={genderOptions}
                  register={register}
                  fullWidth
                ></FieldSelect>
              </FormControl>
              <FormControl errorMessage={errors.dateOfBirth?.message}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <FieldDate
                      fullWidth={true}
                      valid={!errors.dateOfBirth?.message}
                      label="Date of birth"
                      value={
                        field.value
                          ? dayjs(field.value).format('DD/MM/YYYY')
                          : ''
                      }
                      onChange={(date) => {
                        field.onChange(date ? new Date(date) : null);
                      }}
                      format="DD/MM/YYYY"
                      required={isIndividualDateOfBirthMandatory}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Select
                  label="Nationality"
                  id="nationality"
                  register={register}
                  options={countries}
                  placeholder={'Please select a country'}
                  placeholderValue="DEFAULT"
                  fullWidth
                />
              </FormControl>
              <FormControl errorMessage={errors.passportNumber?.message}>
                <Input
                  label="Passport number"
                  id="passportNumber"
                  name="passportNumber"
                  register={register}
                  fullWidth
                />
              </FormControl>
              <FormControl errorMessage={errors.nationalIDNumber?.message}>
                <Input
                  label="National ID number"
                  id="nationalIDNumber"
                  name="nationalIDNumber"
                  register={register}
                  fullWidth
                />
              </FormControl>
              <FormControl errorMessage={errors.otherIDNumber?.message}>
                <Input
                  label="Other ID number"
                  id="otherIDNumber"
                  name="otherIDNumber"
                  register={register}
                  fullWidth
                />
              </FormControl>
            </GridRow>
          </>
        )}
        {!itsIndividualRelatedEntity && (
          <>
            <GridRow>
              <FormControl errorMessage={errors?.countryThree?.message}>
                <FieldSelect
                  valid={errors?.countryThree === undefined}
                  id="countryThree"
                  placeholder="Please Select"
                  placeholderValue="DEFAULT"
                  label="Country 3"
                  options={countries}
                  register={register}
                  fullWidth
                ></FieldSelect>
              </FormControl>
              <FormControl errorMessage={errors.registrationNumber?.message}>
                <Input
                  label="Registration number"
                  id="registrationNumber"
                  name="registrationNumber"
                  register={register}
                  fullWidth
                />
              </FormControl>
              <FormControl errorMessage={errors.swift?.message}>
                <Input
                  label="SWIFT BIC number"
                  id="swift"
                  name="swift"
                  register={register}
                  fullWidth
                />
              </FormControl>
              <FormControl errorMessage={errors.duns?.message}>
                <Input
                  label="DUNS number"
                  id="duns"
                  name="duns"
                  register={register}
                  fullWidth
                />
              </FormControl>
            </GridRow>
          </>
        )}
      </GridFormContainer>
      <ButtonWrapper>
        <Button text onClick={onBackClick}>
          Go back
        </Button>
        <Button secondary type="submit">
          {relatedEntity?.id ? 'Save' : 'Create Related Entity'}
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default StepTwo;
