import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../context/form';

import {
  Wrapper,
  FormHeader,
  FixedWidthButton,
  ButtonWrapper,
} from '../form.styles';
import { FormProvider, useForm } from 'react-hook-form';
import { alphanumeric } from 'helpers/yup-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDebounce, useFetch } from '../../hooks';
import { useAuth } from '../../auth';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { useConfig } from '../../config';
import EssentialInfo from './sections/essentialInfo';
import AdditionalInfo from './sections/additionalInfo';
import { Button } from '../../components/atoms';
import CollapsibleSection from '../../components/molecules/collapsible-section';
import { FormBorderTop } from './step2.styles';
import { ThirdPartyType } from 'types/thirdPartyType';
import {
  individualAdditionalFields,
  companyAdditionalFields,
} from 'auth/constants/thirdPartyAdditionalFields';

const Step2 = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const {
    formDispatch,
    formState: { formData, thirdPartyDataConfig },
  } = useContext(FormContext);

  const {
    trigger: internalIdValidateFetchLazy,
    data: internalIdValidateData,
    loading: internalIdValidateLoading,
  } = useFetch<boolean, unknown, 'value'>(
    `${vantageWebApi}/thirdparty/internalId/validate`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      lazy: true,
      method: 'GET',
    }
  );

  const isBusiness = formData?.type === ThirdPartyType.Business;
  const isIndividual = formData?.type === ThirdPartyType.Individual;
  const [isAnyMandatory, setIsAnyMandatory] = useState(false);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        name: alphanumeric.max(140, 'Maximum 140 characters').when([], {
          is: () => thirdPartyDataConfig.name.isMandatory,
          then: (schema) =>
            schema.required(
              `Please enter ${isBusiness ? 'a company' : 'an individual'} name`
            ),
        }),
        alternativeName: alphanumeric
          .max(140, 'Maximum 140 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.alternativeName.isMandatory,
            then: (schema) =>
              schema.required(
                `Please enter ${
                  isBusiness
                    ? 'an alternative company'
                    : 'an alternative individual'
                } name`
              ),
          }),
        primaryCountry: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.primaryCountry.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        internalId: yup
          .string()
          .max(30, 'Maximum 30 characters')
          .test(
            'validate',
            `${terminologyConfig?.internalIdSingularNaming} already in use for another ${terminologyConfig?.thirdPartySingularNaming}.`,
            (value) => {
              if (internalIdValidateData === null || !value || !value.trim()) {
                return true;
              }
              return internalIdValidateData;
            }
          )
          .when([], {
            is: () => thirdPartyDataConfig.internalId.isMandatory,
            then: (schema) =>
              schema.required(
                `Please enter ${terminologyConfig?.internalIdSingularNaming} name`
              ),
          }),
        duns: yup
          .string()
          .max(19, 'Maximum 19 characters')
          .nullable()
          .test('duns-number', 'Duns needs to be a number', (value) => {
            if (!value || value.length === 0) {
              return true;
            }
            const Regex = new RegExp(/^[0-9]*$/);
            return Regex.test(value);
          })
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.duns.isMandatory,
            then: (schema) => schema.required(`Please enter DUNS`),
          }),
        addressLineOne: yup
          .string()
          .max(140, 'Maximum 140 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.addressLineOne.isMandatory,
            then: (schema) => schema.required(`Please enter an address`),
          }),
        city: yup
          .string()
          .max(60, 'Maximum 60 characters')
          .when([], {
            is: () => thirdPartyDataConfig.city.isMandatory,
            then: (schema) => schema.required(`Please enter a city`),
          }),
        county: yup
          .string()
          .max(60, 'Maximum 60 characters')
          .when([], {
            is: () => thirdPartyDataConfig.county.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a state, region and/or province`),
          }),
        zipCode: yup
          .string()
          .max(10, 'Maximum 10 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.zipCode.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a post code or zip code`),
          }),
        countryTwo: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.countryTwo.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        countryThree: yup.mixed().when([], {
          is: () => thirdPartyDataConfig.countryThree.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        gender: yup
          .number()
          .nullable()
          .when([], {
            is: () => isIndividual && thirdPartyDataConfig.gender.isMandatory,
            then: (schema) => schema.required(`Please enter a gender`),
          }),
        dateOfBirth: yup
          .date()
          .nullable()
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.dateOfBirth.isMandatory,
            then: (schema) => schema.required(`Please enter a date of birth`),
          }),
        nationality: yup.mixed().when([], {
          is: () =>
            isIndividual && thirdPartyDataConfig.nationality.isMandatory,
          then: (schema) =>
            schema.test('screening-setting', 'Please select one!', (value) => {
              return value !== 'DEFAULT';
            }),
        }),
        passportNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.passportNumber.isMandatory,
            then: (schema) => schema.required(`Please enter a passport number`),
          }),
        nationalIDNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.nationalIDNumber.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a national ID number`),
          }),
        otherIDNumber: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .when([], {
            is: () =>
              isIndividual && thirdPartyDataConfig.otherIDNumber.isMandatory,
            then: (schema) => schema.required(`Please enter other ID number`),
          }),
        registrationNumber: yup
          .string()
          .max(25, 'Maximum 25 characters')
          .nullable()
          .when([], {
            is: () =>
              isBusiness && thirdPartyDataConfig.registrationNumber.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a registration number`),
          }),
        swift: yup
          .string()
          .max(20, 'Maximum 20 characters')
          .nullable()
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.swift.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a SWIFT or BIC number`),
          }),
        companyUrl: yup
          .string()
          .max(255, 'Maximum 255 characters')
          .nullable()
          .when([], {
            is: () => isBusiness && thirdPartyDataConfig.companyUrl.isMandatory,
            then: (schema) => schema.required(`Please enter a company website`),
          }),
      })
    ),
    defaultValues: {
      ...formData,
      primaryCountry: formData?.primaryCountry ?? 'DEFAULT',
      duns: formData?.duns ?? null,
      countryTwo: formData?.countryTwo ?? 'DEFAULT',
      countryThree: formData?.countryThree ?? 'DEFAULT',
      nationality: formData?.nationality ?? 'DEFAULT',
      gender: formData?.gender?.toString() ?? '2',
      dateOfBirth: formData?.dateOfBirth ?? null,
      registrationNumber: formData?.registrationNumber ?? null,
      swift: formData?.swift ?? null,
      companyUrl: formData?.companyUrl ?? null,
    },
  });

  const internalIdWatch = methods.watch('internalId');
  const debounceInternalId = useDebounce(internalIdWatch, 500);
  const [isAdditionalInfoCollapsed, setIsAdditionalInfoCollapsed] =
    useState(true);

  useEffect(() => {
    if (debounceInternalId?.trim()) {
      internalIdValidateFetchLazy({
        queryParams: { value: debounceInternalId.trim() },
      });
    }
  }, [debounceInternalId, internalIdValidateFetchLazy]);

  const onSubmit = (data) => {
    formDispatch({
      type: 'SAVE_FORM_DATA',
      payload: {
        form: {
          ...data,
          gender: parseInt(data.gender),
        },
      },
    });
    formDispatch({
      type: 'UPDATE_ACTIVE_STEP',
      payload: { activeStep: 3 },
    });
  };
  useEffect(() => {
    const checkAdditionalMandatoryFields = (fields) => {
      return fields.some((fieldName) => {
        const field = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
        return thirdPartyDataConfig[field]?.isMandatory === true;
      });
    };

    if (isIndividual) {
      const isAnyIndividualAdditionalMandatory = checkAdditionalMandatoryFields(
        individualAdditionalFields
      );
      if (isAnyIndividualAdditionalMandatory) {
        setIsAnyMandatory(true);
      }
    }

    if (isBusiness) {
      const isAnyCompanyAdditionalMandatory = checkAdditionalMandatoryFields(
        companyAdditionalFields
      );
      if (isAnyCompanyAdditionalMandatory) {
        setIsAnyMandatory(true);
      }
    }
  }, [isIndividual, isBusiness, thirdPartyDataConfig]);

  const onError = () => {
    setIsAdditionalInfoCollapsed(false);
  };

  useEffect(() => {
    if (isAnyMandatory) {
      setIsAdditionalInfoCollapsed(false);
    }
  }, [isAnyMandatory]);

  const goBack = () =>
    formDispatch({
      type: 'UPDATE_ACTIVE_STEP',
      payload: { activeStep: 1 },
    });

  const additionalInfoLabelValue = isIndividual
    ? 'Add additional individual details (Address, DOB, etc.)'
    : 'Add additional company details (Address, Registration number, etc.)';

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <FormHeader>
          {terminologyConfig?.thirdPartySingularNaming} details
        </FormHeader>
        <FormBorderTop onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <EssentialInfo
            setIsAdditionalInfoCollapsed={setIsAdditionalInfoCollapsed}
          />
          <CollapsibleSection
            label={additionalInfoLabelValue}
            collapsed={isAdditionalInfoCollapsed}
            setCollapsed={setIsAdditionalInfoCollapsed}
          >
            <AdditionalInfo />
          </CollapsibleSection>

          <ButtonWrapper justifyContent="end">
            <Button text type="button" onClick={() => goBack()}>
              Back
            </Button>
            <FixedWidthButton
              disabled={
                internalIdValidateLoading ||
                (internalIdWatch && internalIdWatch !== debounceInternalId)
              }
              secondary
              type="submit"
            >
              Next Step
            </FixedWidthButton>
          </ButtonWrapper>
        </FormBorderTop>
      </Wrapper>
    </FormProvider>
  );
};

export default Step2;
