import React, { FC } from 'react';
import {
  ResultItem,
  ResultItemLabel,
  ResultItemValue,
  Row,
  ResultItemMatch,
  ResultsListComponent,
} from './ResultsList.styles';

import Button from 'components/atoms/button/Button';
import { ResultsListTypes } from './types';
import { Radio } from 'components/atoms/field-radio/FieldRadio';
import { useFormContext } from 'react-hook-form';
const ResultsList: FC<ResultsListTypes> = ({
  results,
  activeId,
  onClick,
}: ResultsListTypes) => {
  const { register, setValue } = useFormContext();
  if (!results) return null;

  return (
    <ResultsListComponent>
      <tbody>
        {results.map((result, i) => {
          return (
            <Row
              key={result.matchedName + i}
              active={activeId === result.id}
              onClick={() => onClick(result.id)}
              isNew={result.isNew}
            >
              <ResultItem>
                <ResultItemLabel>Name</ResultItemLabel>
                <ResultItemValue>{result.matchedName}</ResultItemValue>
              </ResultItem>
              <ResultItem>
                <ResultItemLabel>Score</ResultItemLabel>
                <ResultItemValue>{result.matchedScore}</ResultItemValue>
              </ResultItem>
              <ResultItem>
                <ResultItemLabel>Country</ResultItemLabel>
                <ResultItemValue>{result.countries}</ResultItemValue>
              </ResultItem>
              <ResultItem>
                <ResultItemLabel>Reason</ResultItemLabel>
                <ResultItemValue>{result.reasonListed}</ResultItemValue>
              </ResultItem>
              <ResultItem>
                <ResultItemLabel>Match</ResultItemLabel>
                <ResultItemMatch>
                  <Radio
                    label="Possible"
                    id={'possible-' + i}
                    value="PossibleMatch"
                    name={`matches.match_${result.id}.state`}
                    showBorder={false}
                    labelRight={true}
                    small={true}
                    register={register}
                  >
                    <span>Possible</span>
                  </Radio>
                  <Radio
                    label="Confirmed"
                    id={'confirmed-' + i}
                    value="Match"
                    name={`matches.match_${result.id}.state`}
                    showBorder={false}
                    labelRight={true}
                    small={true}
                    register={register}
                  >
                    <span>Confirmed</span>
                  </Radio>
                  <Button
                    type="button"
                    id={'clear-' + i}
                    name={'clear-' + i}
                    small={true}
                    outline={true}
                    onClick={() =>
                      setValue(`matches.match_${result.id}.state`, 'NoMatch')
                    }
                  >
                    Clear
                  </Button>
                </ResultItemMatch>
              </ResultItem>
            </Row>
          );
        })}
      </tbody>
    </ResultsListComponent>
  );
};

export default ResultsList;
