import { Icon } from 'components/atoms';
import React, { FC } from 'react';
import {
  CarouselNavigationComponent,
  PageNavigationButton,
  Pagination,
} from './CarouselNavigation.styles';
import { PaginationDot } from 'components/atoms/pagination-dot/PaginationDot.styles';
import { CarouselNavigationProps } from './types';

const CarouselNavigation: FC<CarouselNavigationProps> = ({
  dark = false,
  activeIndex,
  numberOfSlides,
  uniqueId,
  slidesPerPage = 1,
  swiperInstance,
}) => {
  return (
    <CarouselNavigationComponent dark={dark}>
      <PageNavigationButton
        type="button"
        aria-label="Previous slide"
        className={`custom-swiper-navigation-prev-${uniqueId}`}
      >
        <Icon icon="chevron-left" aria-hidden size="xxs" />
      </PageNavigationButton>
      <Pagination>
        {[...Array(numberOfSlides)].map((_, index) => (
          <PaginationDot
            key={index}
            type="button"
            onClick={() => swiperInstance.slideTo((index + 1) * slidesPerPage)}
            isActive={index === activeIndex}
            aria-label={`View page ${index + 1}`}
          />
        ))}
      </Pagination>
      <PageNavigationButton
        type="button"
        aria-label="Next slide"
        className={`custom-swiper-navigation-next-${uniqueId}`}
      >
        <Icon icon="chevron-right" aria-hidden size="xxs" />
      </PageNavigationButton>
    </CarouselNavigationComponent>
  );
};

export default CarouselNavigation;
