import React, { FC } from 'react';
import { FlagIcon } from 'react-flag-kit';
import { CountryComponent } from './Country.styles';
import { CountryProps } from './types';
import { useCountries } from 'context/countries';

const Country: FC<CountryProps> = ({ countryCode, fullWidth = false }) => {
  const countriesData = useCountries();

  const country = countriesData.find((c) => c.code === countryCode);

  return (
    <CountryComponent fullWidth={fullWidth}>
      {country && <FlagIcon code={countryCode} aria-hidden />}
      <span>{country?.name}</span>
    </CountryComponent>
  );
};

export default Country;
