import React, { FC } from 'react';
import {
  CarouselNavigationComponent,
  Pagination,
} from './CarouselNavigation.styles';
import { CarouselNavigationProps } from './types';
import { PaginationDot } from 'components/atoms/pagination-dot/PaginationDot.styles';

interface AuditCarouselNavigationProps extends CarouselNavigationProps {
  onPageChange: (pageIndex: number) => void;
}

const AuditCarouselNavigation: FC<AuditCarouselNavigationProps> = ({
  dark = false,
  activeIndex,
  numberOfSlides,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <CarouselNavigationComponent dark={dark}>
      <Pagination>
        {[...Array(numberOfSlides)].map((_, index) => (
          <PaginationDot
            key={index}
            type="button"
            onClick={() => handlePageChange(index + 1)}
            isActive={index === activeIndex - 1}
            aria-label={`View page ${index + 1}`}
          />
        ))}
      </Pagination>
    </CarouselNavigationComponent>
  );
};

export default AuditCarouselNavigation;
