import styled from 'styled-components';

export const TimelineCarouselSection = styled.section``;

export const AuditTimelineContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;

  &:before {
    position: absolute;
    top: ${({ theme }) => theme.spacing(60)};
    left: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colours.white};
    content: '';
  }

  .swiper-container {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
  }

  .swiper-slide {
    height: auto;
  }
`;

export const TimelineCarouselFilters = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > * + * {
    margin-left: 10px;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const CarouselItemWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

export const CarouselItem = styled.div<{ isActive: boolean }>`
  flex: 0 0 auto;
  display: ${({ isActive }) => (isActive ? 'inline-block' : 'none')};
  height: 400px;
  margin-right: 20px;
  width: calc((100% - 5 * 20px) / 6);
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 0;
`;

export const NavigationButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: 100px;

  &:disabled {
    opacity: 0.25;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;
