import React, { FC, useContext, useEffect, useState } from 'react';
import ResultsList from '../results-list';
import { Pagination } from 'components/molecules';
import {
  ListContainer,
  NotesWrapper,
  RadioButtonContainer,
  Spacer,
  TableCount,
  MultiSelectContainer,
} from './ScreeningTable.styles';
import { ScreeningTableTypes } from './types';
import MultiSelect from 'components/atoms/multi-select';
import TextArea from 'components/atoms/field-textarea/FieldTextarea';
import { Radio } from 'components/atoms/field-radio/FieldRadio';
import { isEqual } from 'date-fns';
import { ScreeningContext } from 'context/screening';
import { useFormContext } from 'react-hook-form';

const ScreeningTable: FC<ScreeningTableTypes> = ({
  matchData,
  notesOptions,
  activeId,
  notes,
  showProfile,
  setNotes,
  setPageNumber,
  state,
}: ScreeningTableTypes) => {
  const { screeningDispatch } = useContext(ScreeningContext);
  const [matchList, setMatchList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { setValue } = useFormContext();

  useEffect(() => {
    let currentNotes = notes;
    notesOptions.forEach((note) => {
      currentNotes[note.id] = note.text;
    });

    setNotes(currentNotes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tempList: any = matchData?.results || [];
    setCurrentPage(matchData?.currentPage || 1);
    if (tempList?.length) {
      if (state === 'UnresolvedAlerts') {
        const newestDate = new Date(matchData.newestDate);

        tempList.forEach((match) => {
          if (isEqual(new Date(match.profileMatchedDate), newestDate)) {
            match.isNew = true;
          } else {
            match.isNew = false;
          }
        });

        tempList.sort((a, b) => {
          if (a.isNew && !b.isNew) {
            return -1;
          } else if (a.isNew && b.isNew) {
            return a.matchedScore > b.matchedScore ? -1 : 1;
          }
        });
      }
    }
    const matches =
      matchData?.results?.reduce((matchesObject, match) => {
        matchesObject[`match_${match.id}`] = {
          id: match.id,
          state: match.state,
        };
        return matchesObject;
      }, {}) ?? {};
    setValue('matches', matches);
    setMatchList([...tempList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchData, screeningDispatch, state]);

  const riskOptions = [
    {
      label: 'Low',
      value: 'low',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
    {
      label: 'High',
      value: 'high',
    },
  ];

  let countries = [];
  let reasonListedOptions = [];
  const facets = matchData?.facets;

  if (matchData && countries.length < facets?.Country?.length) {
    countries = facets?.Country?.map((country) => ({
      name: country,
      value: country,
    }));
  }

  if (matchData && reasonListedOptions.length < facets?.Category?.length) {
    reasonListedOptions = facets?.Category?.map((category) => ({
      name: category,
      value: category,
    }));
  }

  return (
    matchData?.screening?.numberOfMatchesFound > 0 && (
      <>
        <Spacer>
          {matchData.rowCount > 0 && (
            <TableCount>
              Showing {matchData?.firstRowOnPage} - {matchData?.lastRowOnPage}{' '}
              of {matchData?.rowCount}
            </TableCount>
          )}
          {matchData?.rowCount === 0 && <TableCount>No results</TableCount>}
        </Spacer>
        <MultiSelectContainer>
          {countries?.length > 1 && (
            <MultiSelect
              items={countries}
              label={'Filter by country'}
              onChange={({ value, checked }) => {
                screeningDispatch({
                  type: checked
                    ? 'ADD_SELECT_COUNTRY'
                    : 'REMOVE_SELECT_COUNTRY',
                  payload: value,
                });
              }}
            />
          )}
          {reasonListedOptions?.length > 1 && (
            <MultiSelect
              items={reasonListedOptions}
              label={'Filter by reason listed'}
              onChange={({ value, checked }) => {
                screeningDispatch({
                  type: checked ? 'ADD_REASON_LISTED' : 'REMOVE_REASON_LISTED',
                  payload: value,
                });
              }}
            />
          )}
        </MultiSelectContainer>
        <ListContainer>
          <ResultsList
            results={matchList}
            activeId={activeId}
            onClick={(id) => showProfile(id)}
          ></ResultsList>
        </ListContainer>
        {matchData.pageCount > 1 && (
          <Spacer>
            <Pagination
              setCurrentPage={setCurrentPage}
              totalPages={matchData.pageCount || 1}
              currentPage={currentPage}
              label={'Screening pagination'}
              onPageChange={({ currentPage }) => setPageNumber(currentPage)}
            />
          </Spacer>
        )}
        <Spacer>
          {notesOptions.map((note, i) => (
            <NotesWrapper
              key={note.id}
              optionsPadding={i < notesOptions.length - 1}
            >
              <TextArea
                label={note.label}
                id={note.id}
                name={note.id}
                defaultValue={note.text}
                onChange={(resp) => {
                  let tempNotes = notes;
                  tempNotes[note.id] = resp.value;
                  setNotes(tempNotes);
                  return resp;
                }}
                fullWidth
              >
                {notes[note.id]}
              </TextArea>
              {i > 0 && (
                <RadioButtonContainer>
                  {riskOptions.map((option) => (
                    <Radio
                      label={option.label}
                      id={`${option.value}-${note.id}`}
                      key={option.value}
                      value={option.value}
                      name={`risk-score-${note.id}`}
                      showBorder={false}
                      labelRight={true}
                      small={true}
                      checked={option.value === note.score}
                      onChange={({ target }) => {
                        if (target.checked) {
                          let tempNotes = notes;
                          tempNotes[`${note.id}RiskScore`] = target.value;
                          setNotes(tempNotes);
                        }
                      }}
                    >
                      <span style={{ marginLeft: '1rem' }}>{option.label}</span>
                    </Radio>
                  ))}
                </RadioButtonContainer>
              )}
            </NotesWrapper>
          ))}
        </Spacer>
      </>
    )
  );
};

export default ScreeningTable;
