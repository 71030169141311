import styled from 'styled-components';

export const EditMonitoringForm = styled.form`
  max-width: ${({ theme }) => theme.pxToRem(400)};
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(32)};

  .button {
    width: ${({ theme }) => theme.pxToRem(166)};

    &:has(.spinner) {
      padding: 10px;
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }
`;
