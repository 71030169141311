import React, { FC } from 'react';
import { MessageContainer, ProfileContainer } from './ScreeningMessage.styles';

import { ScreeningMessageTypes } from './types';
import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';

const ScreeningMessage: FC<ScreeningMessageTypes> = ({
  dueDiligenceType,
  numberOfMatchesFound,
  numberOfRetries,
  state,
}: ScreeningMessageTypes) => {
  const getMessage = () => {
    const isSelfServiceScreening =
      dueDiligenceType === GatewaySubscriptionTypeNames.SelfServiceScreening;

    if (isSelfServiceScreening && state === 'New' && numberOfRetries <= 0) {
      return 'Your screening order is in progress and will be ready soon. Please refresh the page.';
    }

    if (isSelfServiceScreening && state === 'New' && numberOfRetries > 0) {
      return 'The first attempt of ordering this screening has failed, please wait while trying to get a successful response, it can take up to 50 mins';
    }

    if (state == 'Errored') {
      return `Sorry, we've encountered an issue while initiating your ${
        isSelfServiceScreening ? 'Self' : 'Manage'
      } Service Screening order. Please try again later.`;
    }

    if (numberOfMatchesFound === 0 && state !== 'New') {
      return 'No matches found. Review Status Has Been Set To "Complete - No MatchesFound';
    }
  };
  const message = getMessage();

  return message ? (
    <ProfileContainer>
      <MessageContainer>{message}</MessageContainer>
    </ProfileContainer>
  ) : null;
};

export default ScreeningMessage;
