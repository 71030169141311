import React, { useContext } from 'react';
import { FieldDate, FieldSelect, FormControl } from 'components/atoms';
import { FieldsContainer } from 'form/form.styles';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup } from '../step2.styles';
import Input from 'components/atoms/field-input';
import Select from 'components/atoms/field-select';
import { Radio } from 'components/atoms/field-radio';
import { FormContext } from 'context/form';
import { ThirdPartyType } from 'types/thirdPartyType';
import { useCountryHelper } from 'helpers/countriesHelper';

const AdditionalInfo = () => {
  const {
    register,
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const {
    formState: { formData, thirdPartyDataConfig },
  } = useContext(FormContext);

  const isBusiness = formData?.type === ThirdPartyType.Business;
  const isIndividual = formData?.type === ThirdPartyType.Individual;
  const { sortedCountries: countries } = useCountryHelper();

  const isValid = (field: string) => {
    return !errors?.[field]?.message && !touchedFields?.[field]?.message;
  };

  return (
    <FieldsContainer>
      <FormControl errorMessage={errors.addressLineOne?.message}>
        <Input
          label="Address"
          id="addressLineOne"
          name="addressLineOne"
          register={register}
          fullWidth
          valid={isValid('addressLineOne')}
          required={thirdPartyDataConfig.addressLineOne.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.city?.message}>
        <Input
          label="City"
          id="city"
          name="city"
          register={register}
          fullWidth
          valid={isValid('city')}
          required={thirdPartyDataConfig.city.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.county?.message}>
        <Input
          label="State / Region / Province"
          id="county"
          name="county"
          register={register}
          fullWidth
          valid={isValid('county')}
          required={thirdPartyDataConfig.county.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.zipCode?.message}>
        <Input
          label="Post Code / Zip Code"
          id="zipCode"
          name="zipCode"
          register={register}
          fullWidth
          valid={isValid('zipCode')}
          required={thirdPartyDataConfig.zipCode.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.countryTwo?.message}>
        <Select
          label="Country 2"
          id="countryTwo"
          register={register}
          options={countries}
          placeholder="Please select"
          placeholderValue="DEFAULT"
          fullWidth
          valid={isValid('countryTwo')}
          required={thirdPartyDataConfig.countryTwo.isMandatory}
        />
      </FormControl>
      <FormControl errorMessage={errors.countryThree?.message}>
        <Select
          label="Country 3"
          id="countryThree"
          register={register}
          options={countries}
          placeholder="Please select"
          placeholderValue="DEFAULT"
          fullWidth
          valid={isValid('countryThree')}
          required={thirdPartyDataConfig.countryThree.isMandatory}
        />
      </FormControl>
      {isIndividual && (
        <>
          <FormControl errorMessage={errors.gender?.message}>
            <label>
              Gender{' '}
              {thirdPartyDataConfig.gender.isMandatory && (
                <span className="required"> *</span>
              )}
            </label>
            <RadioGroup>
              <Radio
                label="Female"
                id="female"
                value="1"
                name="gender"
                register={register}
                required={errors.gender?.message}
              >
                <span>Female</span>
              </Radio>
              <Radio
                label="Male"
                id="male"
                value="0"
                name="gender"
                register={register}
                required={errors.gender?.message}
              >
                <span>Male</span>
              </Radio>
              <Radio
                label="Unknown"
                id="unknown"
                value="2"
                name="gender"
                register={register}
                required={errors.gender?.message}
              >
                <span>Unknown</span>
              </Radio>
            </RadioGroup>
          </FormControl>
          <FormControl errorMessage={errors.dateOfBirth?.message}>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { onChange } }) => {
                return (
                  <FieldDate
                    label="Date of birth"
                    value={formData?.dateOfBirth || ''}
                    format="DD/MM/YYYY"
                    onChange={(date) => {
                      onChange(date ? new Date(date) : null);
                    }}
                    fullWidth
                    valid={isValid('dateOfBirth')}
                    required={thirdPartyDataConfig.dateOfBirth.isMandatory}
                  />
                );
              }}
            />
          </FormControl>
          <FormControl errorMessage={errors?.nationality?.message}>
            <FieldSelect
              label="Nationality"
              id="nationality"
              register={register}
              options={countries}
              placeholder="Please Select"
              placeholderValue="DEFAULT"
              fullWidth
              valid={isValid('nationality')}
              required={thirdPartyDataConfig.nationality.isMandatory}
            />
          </FormControl>
          <FormControl errorMessage={errors.passportNumber?.message}>
            <Input
              label="Passport number"
              id="passportNumber"
              name="passportNumber"
              register={register}
              fullWidth
              valid={isValid('passportNumber')}
              required={thirdPartyDataConfig.passportNumber.isMandatory}
            />
          </FormControl>
          <FormControl errorMessage={errors.nationalIDNumber?.message}>
            <Input
              label="National ID number"
              id="nationalIDNumber"
              name="nationalIDNumber"
              register={register}
              fullWidth
              valid={isValid('nationalIDNumber')}
              required={thirdPartyDataConfig.nationalIDNumber.isMandatory}
            />
          </FormControl>
          <FormControl errorMessage={errors.otherIDNumber?.message}>
            <Input
              label="Other ID number"
              id="otherIDNumber"
              name="otherIDNumber"
              register={register}
              fullWidth
              valid={isValid('otherIDNumber')}
              required={thirdPartyDataConfig.otherIDNumber.isMandatory}
            />
          </FormControl>
        </>
      )}

      {isBusiness && (
        <>
          <FormControl errorMessage={errors?.registrationNumber?.message}>
            <Input
              label="Registration number"
              id="registrationNumber"
              name="registrationNumber"
              register={register}
              fullWidth
              valid={isValid('registrationNumber')}
              required={thirdPartyDataConfig.registrationNumber.isMandatory}
            />
          </FormControl>
          <FormControl errorMessage={errors?.swift?.message}>
            <Input
              label="SWIFT/BIC Number"
              id="swift"
              name="swift"
              register={register}
              fullWidth
              valid={isValid('swift')}
              required={thirdPartyDataConfig.swift.isMandatory}
            />
          </FormControl>
          <FormControl errorMessage={errors?.companyUrl?.message}>
            <Input
              label="Company website"
              id="companyUrl"
              name="companyUrl"
              register={register}
              fullWidth
              valid={isValid('companyUrl')}
              required={thirdPartyDataConfig.companyUrl.isMandatory}
            />
          </FormControl>
        </>
      )}
    </FieldsContainer>
  );
};

export default AdditionalInfo;
