import React, { useContext } from 'react';

import { FormControl, Button, FieldRadio } from 'components/atoms';

import FieldSelect from 'components/atoms/field-select';
import Input from 'components/atoms/field-input';

import { FieldsContainer, RadioFieldset } from '../index.styles';
import { RelatedEntityModalContext } from '../../Context';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ButtonWrapper } from '../index.styles';
import { alphanumeric } from 'helpers/yup-validations';
import { ThirdPartyType } from 'types/thirdPartyType';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { RelatedEntityModalFormValues } from '../../types';
import { useCountryHelper } from 'helpers/countriesHelper';

type StepOneProps = {
  relatedEntity: Record<string, any> | undefined;
  noIndividualRelatedEntitiesAllowed?: boolean;
  noBusinessRelatedEntitiesAllowed?: boolean;
  isIndividualRelationshipToThirdPartyMandatory: boolean;
  isCompanyRelationshipToThirdPartyMandatory: boolean;
  onSubmit?: (data: RelatedEntityModalFormValues) => void;
  onCancel?: () => void;
};

export const createSchema = (
  individualLinkTypeRequired: boolean,
  companyLinkTypeRequired: boolean
) => {
  return yup.object().shape({
    type: yup.mixed().test('related-entity', 'Please select one!', (value) => {
      return !!value;
    }),
    name: alphanumeric
      .max(140, 'Maximum 140 characters')
      .required('Required field'),
    alternativeName: alphanumeric.max(140, 'Maximum 140 characters').nullable(),
    linkType: yup
      .string()
      .max(40, 'Maximum 40 characters')
      .when('type', {
        is: (val: string) => {
          return val === 'Individual' && individualLinkTypeRequired;
        },
        then: (schema) =>
          schema
            .required('Required field')
            .test('is-falsy', 'Required field', (value) => {
              return !!value && value.trim() !== '';
            }),
      })
      .when('type', {
        is: (val: string) => val === 'Business' && companyLinkTypeRequired,
        then: (schema) =>
          schema
            .required('Required field')
            .test('is-falsy', 'Required field', (value) => {
              return !!value && value.trim() !== '';
            }),
      })
      .nullable(),
    primaryCountry: yup
      .mixed()
      .test('related-entity', 'Please select one!', (value) => {
        return value !== 'DEFAULT';
      }),
  });
};

const StepOne: React.FC<StepOneProps> = ({
  relatedEntity,
  isIndividualRelationshipToThirdPartyMandatory = false,
  isCompanyRelationshipToThirdPartyMandatory = false,
  onSubmit,
  onCancel,
}) => {
  const { state: formState } = useContext(RelatedEntityModalContext);
  const terminologyConfig = useTerminologyConfig();

  const isForEdit = relatedEntity?.id;

  const isRelatedEntityTypeIndividual: boolean =
    relatedEntity?.type === ThirdPartyType.Individual;

  const isRelatedEntityTypeBusiness: boolean =
    relatedEntity?.type === ThirdPartyType.Business;

  const isIndividualDisabled = isRelatedEntityTypeBusiness && isForEdit;

  const isBusinessDisabled = isRelatedEntityTypeIndividual && isForEdit;

  let preselectedTypeOnCreate = null;

  const {
    register,
    handleSubmit,
    formState: { errors: formValidationErrors },
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      createSchema(
        isIndividualRelationshipToThirdPartyMandatory,
        isCompanyRelationshipToThirdPartyMandatory
      )
    ),
    defaultValues: {
      ...relatedEntity,
      ...formState,
      type:
        formState?.type ||
        relatedEntity?.type ||
        preselectedTypeOnCreate ||
        null,
      primaryCountry:
        formState?.primaryCountry || relatedEntity?.primaryCountry || 'DEFAULT',
    },
  });
  const { sortedCountries: countries } = useCountryHelper();

  const selectedType = watch('type');

  const isLinkTypeRequired =
    (selectedType === ThirdPartyType.Individual &&
      isIndividualRelationshipToThirdPartyMandatory) ||
    (selectedType === ThirdPartyType.Business &&
      isCompanyRelationshipToThirdPartyMandatory);

  return (
    <FieldsContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl errorMessage={formValidationErrors?.type?.message}>
          <label>
            Entity Type
            <span className="required bold"> *</span>
          </label>
          <RadioFieldset>
            <FieldRadio
              label="Individual"
              id="individualRadioButton"
              value="Individual"
              name="type"
              showBorder={false}
              labelRight={true}
              disabled={isIndividualDisabled}
              register={register}
              small
            />
            <FieldRadio
              label="Company"
              id="companyRadioButton"
              value="Business"
              name="type"
              showBorder={false}
              labelRight={true}
              disabled={isBusinessDisabled}
              register={register}
              small
            />
          </RadioFieldset>
        </FormControl>

        <FormControl errorMessage={formValidationErrors?.name?.message}>
          <Input
            label="Name"
            id="name"
            name="name"
            register={register}
            fullWidth
            valid={formValidationErrors?.name === undefined}
            required
          />
        </FormControl>
        <FormControl
          errorMessage={formValidationErrors?.alternativeName?.message}
        >
          <Input
            label="Name (alternative script)"
            id="alternativeName"
            name="alternativeName"
            register={register}
            valid={formValidationErrors?.alternativeName === undefined}
            fullWidth
          />
        </FormControl>
        <FormControl
          errorMessage={formValidationErrors?.primaryCountry?.message}
        >
          <FieldSelect
            valid={formValidationErrors?.primaryCountry === undefined}
            id="primaryCountry"
            placeholder="Please Select"
            placeholderValue="DEFAULT"
            label="Primary country"
            options={countries}
            register={register}
            fullWidth
            required
          ></FieldSelect>
        </FormControl>
        <FormControl errorMessage={formValidationErrors?.linkType?.message}>
          <Input
            label={`Relationship to ${terminologyConfig?.thirdPartySingularNaming}`}
            id="linkType"
            name="linkType"
            register={register}
            fullWidth
            required={isLinkTypeRequired}
          />
        </FormControl>
        <ButtonWrapper>
          <Button text type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button secondary type="submit">
            Next step
          </Button>
        </ButtonWrapper>
      </form>
    </FieldsContainer>
  );
};

export default StepOne;
