import React from 'react';
import { Date as DateJsx } from 'components/atoms';
import getSymbolFromCurrency from 'currency-symbol-map';
import { statusMapper } from 'services/status-mapper';
import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';
import { calculateBusinessDays } from 'helpers/formDataHelper';

export const mapSummaryData = (statusData, countriesData) => {
  if (!statusData) {
    return [];
  }
  const isScreeningLevel = statusData?.dueDiligenceType?.includes('Screening');

  const getCountryName = (code) => {
    if (!code) return null;
    const country = countriesData.find((country) => country.code === code);
    return country ? country.name : null;
  };

  const requestDetails = isScreeningLevel
    ? statusData.request
    : statusData?.dueDiligenceDetails?.screeningSearchDetails ?? {};

  const isIndividual = isScreeningLevel
    ? requestDetails?.screeningTypeId === 'Individual'
    : statusData?.thirdParty?.type === 'Individual';

  if (isScreeningLevel) {
    return [
      {
        label: 'Name screened',
        value: requestDetails.screeningSearchDetails?.name,
      },
      {
        label: 'Alternative name',
        value: requestDetails.screeningSearchDetails?.alternativeName,
      },
      {
        label: 'Primary country',
        value: requestDetails.screeningSearchDetails?.countryCode
          ? getCountryName(requestDetails.screeningSearchDetails?.countryCode)
          : null,
      },
      {
        label: 'Address',
        value: requestDetails.screeningSearchDetails?.address?.formattedAddress,
      },
      {
        label: 'Country 2',
        value: requestDetails.screeningSearchDetails?.countryCodeTwo
          ? getCountryName(
              requestDetails.screeningSearchDetails?.countryCodeTwo
            )
          : null,
      },
      {
        label: 'Country 3',
        value: requestDetails.screeningSearchDetails?.countryCodeThree
          ? getCountryName(
              requestDetails.screeningSearchDetails?.countryCodeThree
            )
          : null,
      },
      {
        label: 'Screening ID',
        value: statusData?.screeningId,
      },
      {
        label: isIndividual
          ? 'National ID Number'
          : 'Company Registration Number',
        value:
          requestDetails.screeningSearchDetails[
            isIndividual ? 'nationalIdNumber' : 'registrationNumber'
          ],
      },
      {
        label: isIndividual ? 'Passport ID Number' : 'DUNS Number',
        value:
          requestDetails.screeningSearchDetails[
            isIndividual ? 'passportNumber' : 'duns'
          ],
      },
      {
        label: isIndividual ? 'Other ID Number' : 'SWIFT BIC',
        value:
          requestDetails.screeningSearchDetails[
            isIndividual ? 'otherId' : 'swiftBic'
          ],
      },
      {
        label: 'Entity type',
        value: requestDetails.screeningTypeId,
      },
      {
        label: 'Parent',
        value: requestDetails.parentId,
      },
      {
        label: requestDetails.screeningSearchDetails?.referenceFieldOneName,
        value: requestDetails.screeningSearchDetails?.referenceFieldOne,
      },
      {
        label: requestDetails.screeningSearchDetails?.referenceFieldTwoName,
        value: requestDetails.screeningSearchDetails?.referenceFieldTwo,
      },
      {
        label: requestDetails.screeningSearchDetails?.referenceFieldThreeName,
        value: requestDetails.screeningSearchDetails?.referenceFieldThree,
      },
    ];
  } else if (
    statusData?.dueDiligenceType ===
    GatewaySubscriptionTypeNames.ManagedServiceDueDiligence
  ) {
    let entityTypeProps = [];

    if (isIndividual) {
      entityTypeProps = [
        {
          label: 'National ID',
          value: requestDetails.nationalIdNumber || '-',
        },
        {
          label: 'Passport ID',
          value: requestDetails.passportNumber || '-',
        },
        {
          label: 'Other ID',
          value: requestDetails.otherId || '-',
        },
        {
          label: 'Gender',
          value: requestDetails.gender || 'Unknown',
        },
        {
          label: 'Date of birth',
          value:
            requestDetails.dateOfBirth &&
            requestDetails.dateOfBirth !== '0001-01-01T00:00:00' ? (
              <DateJsx dateTime={requestDetails.dateOfBirth} />
            ) : (
              '-'
            ),
        },
        {
          label: 'Nationality',
          value: requestDetails.nationality
            ? getCountryName(requestDetails.nationality)
            : '-',
        },
      ];
    } else {
      entityTypeProps = [
        {
          label: 'Registration Number',
          value: requestDetails.registrationNumber || '-',
        },
        {
          label: 'DUNS',
          value: requestDetails.duns || '-',
        },
        {
          label: 'SWIFT / BIC Number',
          value: requestDetails.swiftBic || '-',
        },
      ];
    }

    return [
      {
        label: 'Name',
        value: requestDetails.name,
      },
      {
        label: 'Alternative Name',
        value: requestDetails.alternativeName || '-',
      },
      {
        label: 'Primary Country',
        value: requestDetails.countryCode
          ? getCountryName(requestDetails.countryCode)
          : '-',
      },
      {
        label: 'Address',
        value: requestDetails?.address?.addressLineOne || '-',
      },
      {
        label: 'Country2',
        value: requestDetails.countryCodeTwo
          ? getCountryName(requestDetails.countryCodeTwo)
          : '-',
      },
      {
        label: 'Country3',
        value: requestDetails.countryCodeThree
          ? getCountryName(requestDetails.countryCodeThree)
          : '-',
      },
      ...entityTypeProps,
      {
        label: 'Entity Type',
        value: statusData?.thirdParty?.type,
      },
      {
        label: 'Reference 1',
        value: requestDetails.referenceFieldOne || '-',
      },
      {
        label: 'Reference 2',
        value: requestDetails.referenceFieldTwo || '-',
      },
      {
        label: 'Reference 3',
        value: requestDetails.referenceFieldThree || '-',
      },
    ];
  } else {
    // UBO
  }
};

export const mapSettingsData = (
  statusData,
  addOns,
  dueDiligenceTypes,
  internalIdSingularNaming
) => {
  if (!statusData) {
    return [];
  }

  const getDescription = (id) => {
    return addOns.find((obj) => obj.id === id);
  };

  let globalSearch = [];
  const isScreeningLevel = statusData?.dueDiligenceType?.includes('Screening');
  const selfServiceScreening =
    statusData?.dueDiligenceType ===
    GatewaySubscriptionTypeNames.SelfServiceScreening;
  const screeningLevel = dueDiligenceTypes?.find((x) => x.name === 'Screening');

  const globalSearchEnabled = selfServiceScreening
    ? screeningLevel?.dueDiligenceTypes?.find(
        ({ name }) => name === 'Self Service'
      )?.globalSearchEnabled
    : screeningLevel?.dueDiligenceTypes?.find(
        ({ name }) => name === 'Managed Service'
      )?.globalSearchEnabled;

  if (statusData?.request?.screeningTypeId === 'Individual') {
    globalSearch =
      globalSearchEnabled &&
      statusData?.request?.screeningSearchDetails?.isGlobalSearch !== null
        ? [
            {
              label: 'Global search',
              value: statusData?.request?.screeningSearchDetails?.isGlobalSearch
                ? 'enabled'
                : 'disabled',
            },
          ]
        : [];
  } else {
    globalSearch = [
      {
        label: 'Global search',
        value: statusData?.request?.screeningSearchDetails?.isGlobalSearch
          ? 'enabled'
          : 'disabled',
      },
    ];
  }

  if (isScreeningLevel) {
    let selfServiceProps = [];
    if (selfServiceScreening) {
      selfServiceProps = [
        {
          label: 'Date screened',
          value: <DateJsx dateTime={statusData?.creationDate} />,
        },
        {
          label: 'Screened by',
          value: statusData?.screenedByAnalyst,
        },
        {
          label: 'Screening settings (initial)',
          value: statusData?.screeningSearchType?.description,
        },
        {
          label: 'Last Resolved on ',
          value: statusData?.lockedByUserDateTime && (
            <DateJsx dateTime={statusData?.lockedByUserDateTime} />
          ),
        },
      ];
    }

    return [
      ...selfServiceProps,
      {
        label: 'Last Resolved by',
        value: statusData?.lockedByUser,
      },
      {
        label: 'Client Monitoring',
        value: statusData?.client?.ongoingScreeningEnabled
          ? 'enabled'
          : 'disabled',
      },
      {
        label: 'Monitoring',
        value: statusData?.client?.ongoingScreeningEnabled
          ? statusData?.hasMonitoring
            ? 'enabled'
            : 'disabled'
          : 'disabled',
      },
      {
        label: 'Monitoring Settings',
        value: statusData?.ongoingScreeningSearchType?.description || ' ',
      },
      ...globalSearch,
    ];
  } else if (
    statusData?.dueDiligenceType ===
    GatewaySubscriptionTypeNames.ManagedServiceDueDiligence
  ) {
    const status = statusMapper(
      statusData.screeningReportStatusId,
      statusData.state,
      statusData.dueDiligenceType,
      statusData.reportStatus
    );
    const ddDetails = statusData?.dueDiligenceDetails;

    const fee = ddDetails.reportTemplate.fee
      ? ` - ${getSymbolFromCurrency(
          ddDetails.reportTemplate.feeCurrencyCode
        )}${ddDetails.reportTemplate.fee.toFixed()}`
      : '';

    const maxFee =
      ddDetails.reportTemplate.isFeeRange && ddDetails.reportTemplate.maxFee
        ? ` - ${getSymbolFromCurrency(
            ddDetails.reportTemplate.feeCurrencyCode
          )}${ddDetails.reportTemplate.maxFee.toFixed()}`
        : '';

    const feeSection = `${fee}${maxFee}`;

    const addons = ddDetails.addOns.map((a) => {
      const optionalAddOnValue = ddDetails.reportTemplate.optionalAddOns.find(
        (ao) => ao.optionalAddOnId === a
      );
      const addonFee = optionalAddOnValue?.fee
        ? `${getSymbolFromCurrency(
            optionalAddOnValue.feeCurrencyCode
          )}${optionalAddOnValue.fee.toFixed()}`
        : '';

      return {
        label: `Add on ${getDescription(a).description}`,
        value: `${addonFee}`,
      };
    });

    const globalCustomFieldsKeys = Object.keys(
      ddDetails.dueDiligenceCustomFieldsConfig
    );

    const globalCustomFieldsValues = globalCustomFieldsKeys
      .filter((k) => ddDetails.dueDiligenceCustomFieldsConfig[k].isEnabled)
      .map((key) => {
        return {
          label: ddDetails.dueDiligenceCustomFieldsConfig[key].label,
          value: ddDetails[key],
        };
      });

    const customFieldsKeys = Object.keys(
      ddDetails.reportTemplate.customFieldsConfig
    );

    const customFieldsValues = customFieldsKeys
      .filter((k) => ddDetails.reportTemplate.customFieldsConfig[k].isEnabled)
      .map((key, index) => {
        index++;
        const fieldName = `reportCustomField${index}`;
        return {
          label: ddDetails.reportTemplate.customFieldsConfig[key].label,
          value: ddDetails[fieldName],
        };
      });

    const relatedEntities =
      (ddDetails.relatedEntityScreenings &&
        ddDetails.relatedEntityScreenings.map((re) => ({
          label: 'Related Entity',
          value: `${re.name}, ${re.relationship || ''}, ${re.screeningId}`,
        }))) ||
      [];

    if (addons.length === 0) {
      addons.push({ label: 'Add on', value: '' });
    }

    if (relatedEntities.length === 0) {
      relatedEntities.push({ label: 'Related entity', value: '' });
    }

    const getDeliveryDate = () => {
      if (status === 'In progress' || !statusData.submittedDate) {
        let sla = null;
        sla =
          statusData?.dueDiligenceType == 'ManagedServiceDueDiligence'
            ? ddDetails.reportTemplate.managedServiceDueDiligenceSLA
            : statusData.client.sla;

        if (!sla) {
          return 'N/A';
        }
        const createdDate = new Date(ddDetails.orderDate);
        const slaDate = calculateBusinessDays(createdDate, sla);
        return (
          <DateJsx
            dateTime={slaDate instanceof Date ? slaDate.toUTCString() : slaDate}
            additionalText="(Estimated)"
          />
        );
      }
      return <DateJsx dateTime={statusData.submittedDate} />;
    };

    return [
      {
        label: 'Report Level',
        value: `${ddDetails.reportTemplate.reportName}${feeSection}`,
      },
      ...addons,
      {
        label: 'Date Ordered',
        value: <DateJsx dateTime={ddDetails.orderDate} />,
      },
      {
        label: 'Ordered By',
        value: ddDetails.orderedBy.fullName,
      },
      {
        label: internalIdSingularNaming,
        value: ddDetails.internalId,
      },
      {
        label: 'Report ID',
        value: ddDetails.reportId,
      },
      {
        label: 'Screening ID',
        value: ddDetails.thirdPartyScreeningId,
      },
      {
        label: 'Delivery Date',
        value: getDeliveryDate(),
      },
      ...relatedEntities,
      ...globalCustomFieldsValues,
      ...customFieldsValues,
    ];
  } else {
    // UBO
  }
};

export const mapNotesData = (statusData) => {
  let notesOptions = [
    {
      label: 'Commentary notes',
      id: 'resolutionNotes',
      text: statusData?.resolutionNotes,
      score: statusData?.resolultionNotesRiskScore,
    },
  ];
  return notesOptions;
};
