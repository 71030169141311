import React, { FC, useState, useEffect } from 'react';
import { TimelineCarouselProps } from './types';
import { SectionHeading } from 'components/molecules';
import {
  TimelineCarouselSection,
  TimelineCarouselFilters,
  AuditTimelineContainer,
  CarouselContainer,
  CarouselItemWrapper,
  CarouselItem,
  NavigationButton,
  NavigationContainer,
  LoadingContainer,
} from './TimelineCarousel.styles';
import { Loading, Icon } from 'components/atoms';
import { useTerminologyConfig } from 'context/terminologyConfig';
import AuditCarouselNavigation from 'components/molecules/carousel-navigation/AuditCarouselNavigation';

const TimelineCarousel: FC<TimelineCarouselProps> = ({
  children,
  error = false,
  filters,
  loading = false,
  uniqueId,
  onPageChange,
  currentPage,
  totalItems,
  totalPages,
  pageSize = 6,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const terminologyConfig = useTerminologyConfig();

  useEffect(() => {
    if (onPageChange) {
      onPageChange(currentPage);
    }
  }, [currentPage, onPageChange]);

  useEffect(() => {
    setActiveIndex((currentPage - 1) * pageSize);
  }, [currentPage, pageSize]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + pageSize, totalItems - pageSize)
      );
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setActiveIndex((prevIndex) => Math.max(prevIndex - pageSize, 0));
      onPageChange(currentPage - 1);
    }
  };

  return (
    <TimelineCarouselSection>
      <SectionHeading
        dark
        border
        text={`${terminologyConfig?.thirdPartySingularNaming} audit`}
      >
        {filters && (
          <TimelineCarouselFilters>{filters}</TimelineCarouselFilters>
        )}
      </SectionHeading>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : error ? (
        <p>Timeline could not be loaded</p>
      ) : (
        <AuditTimelineContainer>
          <CarouselContainer>
            {totalPages > 1 && (
              <NavigationButton
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`custom-carousel-navigation-prev-${uniqueId}`}
              >
                <Icon
                  cursor={currentPage > 1 ? 'pointer' : ''}
                  icon="chevron-left"
                  aria-hidden
                  colour={'white'}
                  size="xxs"
                />
              </NavigationButton>
            )}
            <CarouselItemWrapper>
              {React.Children.map(children, (child, index) => (
                <CarouselItem key={index} isActive={activeIndex != null}>
                  {child}
                </CarouselItem>
              ))}
            </CarouselItemWrapper>
            {totalPages > 1 && (
              <NavigationButton
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`custom-carousel-navigation-next-${uniqueId}`}
              >
                <Icon
                  cursor={currentPage < totalPages ? 'pointer' : ''}
                  icon="chevron-right"
                  aria-hidden
                  colour={'white'}
                  size="xxs"
                />
              </NavigationButton>
            )}
          </CarouselContainer>
          {totalPages > 1 && (
            <NavigationContainer>
              <AuditCarouselNavigation
                dark={true}
                numberOfSlides={totalPages}
                activeIndex={currentPage}
                uniqueId={uniqueId}
                slidesPerPage={pageSize}
                onPageChange={onPageChange}
              />
            </NavigationContainer>
          )}
        </AuditTimelineContainer>
      )}
    </TimelineCarouselSection>
  );
};

export default TimelineCarousel;
