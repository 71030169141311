import { Date } from 'components/atoms';
import React, { FC } from 'react';
import ScreeningDetails from '../screening-details';

import { OrderDetailsTypes } from './types';

const OrderDetails: FC<OrderDetailsTypes> = ({
  details,
}: OrderDetailsTypes) => {
  const linkTexts = {
    CompleteMatchesFound: 'Complete - Matches found',
    CompleteAllMatchesDiscarded: 'Complete - All matches discarded',
    CompleteNoMatchesFound: 'Complete - No matches found',
    CompleteConfirmedMatches: 'Complete - Confirmed matches',
    CompletePossibleMatchesFound: 'Complete - Possible matches',
    Incomplete: 'Incomplete',
    InProgress: 'In Progress',
  };

  const data = [
    {
      label: 'Current status: ',
      value: linkTexts[details?.screeningReportStatusId],
    },
    {
      label: 'Date ordered: ',
      value: <Date dateTime={details?.creationDate} />,
    },
    { label: 'Ordered by: ', value: details?.client?.name },
    {
      label: 'Date delivered: ',
      value: <Date dateTime={details?.finalisedDate} />,
    },
  ];

  const contact = () => {
    window.location.href = 'mailto:vantagesupport@controlrisks.com';
  };

  return (
    <ScreeningDetails
      heading="Order details"
      subHeading="Details: "
      details={data}
      ctaLabel="Contact Control Risks"
      ctaClick={contact}
      reportLink={
        details?.screeningReportStatusId !== 'Incomplete'
          ? `/report/${details?.screeningId}`
          : null
      }
    />
  );
};

export default OrderDetails;
