import React from 'react';
import styled from 'styled-components';
import Loading from 'components/atoms/loading';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  margin: ${({ theme }) => theme.spacing(20)} 0;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = ({ error }) => (
  <StyledContainer>
    {error ? (
      <h1>An unexpected error occurred. Please try again.</h1>
    ) : (
      <Loading />
    )}
  </StyledContainer>
);

export default LoadingContainer;
