import React, { FC } from 'react';
import { DateProps } from './types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const DateComponent: FC<DateProps> = ({
  dateTime,
  format = 'YYYY/MM/DD',
  additionalText = null,
}) => {
  // !Invalid datetime strings will cause an exception.
  try {
    const userTz = dayjs.tz.guess();
    const tz = userTz != 'Etc/Unknown' ? userTz : 'Etc/UTC';
    const dateObject = new Date(dateTime);
    const date = dayjs.tz(dateObject, tz);

    const isDateValid = date.isValid() && dateTime !== '0001-01-01T00:00:00';
    return (
      <time dateTime={dateTime}>
        {isDateValid ? date.format(format) : ''}
        {additionalText && ` ${additionalText}`}
      </time>
    );
  } catch (error) {
    return null;
  }
};

export default DateComponent;
