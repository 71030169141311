import {
  DocumentCategory,
  DocumentsConfig,
  SelectedRelatedEntity,
  VerificationDateConfig,
} from 'pages/initiate-due-diligence/types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Stroke } from './MultipleDocumentUploadWrapper.styles';
import MultipleUploadOption from 'components/molecules/multiple-upload-option';
import { ThirdPartyType } from 'types/thirdPartyType';
import DocumentUploadModal from '../document-upload-modal';
import {
  DocumentData,
  FileInformationResponse,
  fileInformationResponseToDocumentData,
  isIndividualRelatedEntityDocumentRequiredDependingOnAge,
} from 'helpers/documentHelper';
import DocumentSelectModal from '../document-select-modal';
import { isVerificationDateValid } from 'helpers/verificationDateHelper';
import { SelectableDocument } from '../document-select-modal/types';
import { FieldError } from 'react-hook-form';

interface UploadModalState {
  isOpen: boolean;
  categoryId: number;
  maxUploads: number;
  verificationDateConfig: VerificationDateConfig | null;
  isOptional: boolean;
  isRelatedEntityDocument: boolean;
}

interface SelectModalState {
  isOpen: boolean;
  categoryId: number;
  maxUploads: number;
  verificationDateConfig: VerificationDateConfig | null;
  isOptional: boolean;
  isRelatedEntityDocument: boolean;
}

const defaultUploadModalState: UploadModalState = {
  isOpen: false,
  categoryId: undefined,
  maxUploads: undefined,
  verificationDateConfig: null,
  isOptional: false,
  isRelatedEntityDocument: false,
};

const defaultSelectModalState: SelectModalState = {
  isOpen: false,
  categoryId: undefined,
  maxUploads: undefined,
  verificationDateConfig: null,
  isOptional: false,
  isRelatedEntityDocument: false,
};

interface MultipleUploadOptionsSectionTypes {
  thirdPartyId: number;
  thirdPartyType: ThirdPartyType;
  thirdPartyFiles: DocumentData[];
  documentsConfig: DocumentsConfig;
  documents: DocumentData[];
  categories: DocumentCategory[];
  selectedRelatedEntities: SelectedRelatedEntity[];
  selectedEntityId: number;
  isThirdPartySelected: boolean;
  onSuccessDocumentUpload?: (document: DocumentData) => void;
  onDeleteDocument?: (
    id: string,
    entityId: number,
    isOptional: boolean
  ) => void;
  onAttachDocuments?: (documents: DocumentData[]) => void;
  error: FieldError;
}

const MultipleUploadOptionsSection: FC<MultipleUploadOptionsSectionTypes> = ({
  thirdPartyId,
  thirdPartyType,
  thirdPartyFiles,
  documentsConfig,
  documents,
  categories,
  selectedRelatedEntities,
  selectedEntityId,
  onSuccessDocumentUpload,
  onDeleteDocument,
  onAttachDocuments,
  isThirdPartySelected,
  error,
}: MultipleUploadOptionsSectionTypes) => {
  const [documentUploadModalState, setDocumentUploadModalState] =
    useState<UploadModalState>(defaultUploadModalState);
  const [selectModalState, setSelectModalState] = useState<SelectModalState>(
    defaultUploadModalState
  );

  const isRelatedEntitySelected = !isThirdPartySelected;

  const isValid = useCallback(
    (categoryId: number) => {
      if (error?.type?.includes(categoryId.toString())) {
        return false;
      }
      return true;
    },
    [error?.type]
  );

  const isOptionalValid = useMemo(() => {
    if (error?.type?.includes('optional')) {
      return false;
    }
    return true;
  }, [error?.type]);

  const selectedRelatedEntity = useMemo(() => {
    return selectedRelatedEntities.find((x) => x.id === selectedEntityId);
  }, [selectedRelatedEntities, selectedEntityId]);

  const selectedEntityType = isThirdPartySelected
    ? thirdPartyType
    : (selectedRelatedEntity?.type as ThirdPartyType);

  const thirdPartyConfiguredDocuments = useMemo(() => {
    if (thirdPartyType === ThirdPartyType.Business) {
      return documentsConfig.thirdPartyDocuments
        .thirdPartyConfiguredDocumentsBusiness;
    } else {
      return documentsConfig.thirdPartyDocuments
        .thirdPartyConfiguredDocumentsIndividual;
    }
  }, [thirdPartyType, documentsConfig.thirdPartyDocuments]);

  const relatedEntityConfiguredDocuments = useMemo(() => {
    if (selectedRelatedEntity?.type === ThirdPartyType.Business) {
      return documentsConfig.relatedEntitiesDocuments
        .relatedEntitiesConfiguredDocumentsBusiness;
    } else {
      return documentsConfig.relatedEntitiesDocuments
        .relatedEntitiesConfiguredDocumentsIndividual;
    }
  }, [selectedRelatedEntity?.type, documentsConfig.relatedEntitiesDocuments]);

  const getLabelByCategoryId = (
    categories: DocumentCategory[],
    categoryId: number
  ) => {
    const categoryRecord = categories.find(
      (category) => category.id === categoryId
    );

    return categoryRecord.category;
  };

  const handleNewDocumentUploadForRequiredDocuments = (
    categoryId: number,
    maxUploads: number,
    verificationDateConfig: VerificationDateConfig,
    isRelatedEntityDocument: boolean
  ) => {
    setDocumentUploadModalState({
      isOpen: true,
      categoryId: categoryId,
      maxUploads,
      verificationDateConfig,
      isOptional: false,
      isRelatedEntityDocument,
    });
  };

  const handleNewDocumentUploadForOptionalDocuments = (maxUploads: number) => {
    setDocumentUploadModalState({
      isOpen: true,
      categoryId: undefined,
      maxUploads,
      verificationDateConfig: null,
      isOptional: true,
      isRelatedEntityDocument: false,
    });
  };

  const handleSelectModalForRequiredDocuments = (
    categoryId: number,
    maxUploads: number,
    verificationDateConfig: VerificationDateConfig,
    isRelatedEntityDocument: boolean
  ) => {
    setSelectModalState({
      isOpen: true,
      categoryId,
      maxUploads,
      verificationDateConfig,
      isOptional: false,
      isRelatedEntityDocument,
    });
  };

  const handleSelectModalForOptionalDocuments = (maxUploads: number) => {
    setSelectModalState({
      isOpen: true,
      categoryId: undefined,
      maxUploads,
      verificationDateConfig: null,
      isOptional: true,
      isRelatedEntityDocument: false,
    });
  };

  const filterForRequiredDocumentCardsList = (
    documents: DocumentData[],
    entityId: number,
    categoryId: number,
    isRelatedEntityDocument: boolean
  ) => {
    return documents.filter(
      (doc) =>
        doc.categoryId === categoryId &&
        doc.entityId === entityId &&
        doc.isRelatedEntityDocument === isRelatedEntityDocument &&
        !doc.isOptionalDocument
    );
  };

  const filterForOptionalDocumentCardsList = (documents: DocumentData[]) => {
    return documents.filter(
      (doc) => doc.isOptionalDocument && !doc.isRelatedEntityDocument
    );
  };

  const filterSelectableFiles = (
    thirdPartyFiles: DocumentData[],
    categoryId: number,
    verificationDateConfig: VerificationDateConfig,
    isOptional: boolean
  ): SelectableDocument[] => {
    if (isOptional) {
      return thirdPartyFiles.map((tFile) => ({
        ...tFile,
        isOptionalDocument: true,
        selected: false,
      }));
    }

    return thirdPartyFiles
      .filter((tFile) => tFile.categoryId === categoryId)
      .filter((tFile) => {
        if (!verificationDateConfig) {
          return true;
        }

        if (!tFile.verificationDate) {
          return false;
        }

        return isVerificationDateValid(
          verificationDateConfig,
          tFile.verificationDate
        );
      })
      .map((tFile) => ({
        ...tFile,
        isOptionalDocument: false,
        selected: false,
      }));
  };

  const getRemainingUploads = (
    categoryMaxUploads: number,
    selectModalState: SelectModalState,
    isOptional: boolean
  ) => {
    if (isOptional) {
      return (
        categoryMaxUploads -
        filterForOptionalDocumentCardsList(documents).length
      );
    }

    return (
      categoryMaxUploads -
      filterForRequiredDocumentCardsList(
        documents,
        selectedEntityId,
        selectModalState.categoryId,
        selectModalState.isRelatedEntityDocument
      ).length
    );
  };

  const handleSuccessDocumentUpload = (
    fileInformation: FileInformationResponse,
    entityId: number,
    isRelatedEntityDocument: boolean,
    isOptional: boolean
  ) => {
    const documentData = fileInformationResponseToDocumentData(
      fileInformation,
      isRelatedEntityDocument,
      entityId,
      isOptional
    );
    onSuccessDocumentUpload(documentData);
  };

  const handleAttachDocuments = (
    documents: SelectableDocument[],
    entityId: number,
    isRelatedEntityDocument: boolean
  ) => {
    onAttachDocuments(
      documents.map((doc) => ({ ...doc, entityId, isRelatedEntityDocument }))
    );
  };

  return (
    <>
      {isThirdPartySelected && (
        <>
          {documentsConfig.isRequiredDocumentsForThirdParty && (
            <>
              {thirdPartyConfiguredDocuments.map((configuredDocument) => {
                return (
                  <MultipleUploadOption
                    key={configuredDocument.categoryId}
                    label={getLabelByCategoryId(
                      categories,
                      configuredDocument.categoryId
                    )}
                    required={true}
                    showSidebar={true}
                    maxUploads={configuredDocument.maxUploads}
                    valid={isValid(configuredDocument.categoryId)}
                    documents={filterForRequiredDocumentCardsList(
                      documents,
                      selectedEntityId,
                      configuredDocument.categoryId,
                      false
                    )}
                    onExistingDocumentUpload={() =>
                      handleSelectModalForRequiredDocuments(
                        configuredDocument.categoryId,
                        configuredDocument.maxUploads,
                        configuredDocument.verificationDateConfig,
                        false
                      )
                    }
                    onNewDocumentUpload={() => {
                      handleNewDocumentUploadForRequiredDocuments(
                        configuredDocument.categoryId,
                        configuredDocument.maxUploads,
                        configuredDocument.verificationDateConfig,
                        false
                      );
                    }}
                    onRemoveDocument={(id) =>
                      onDeleteDocument(id, selectedEntityId, false)
                    }
                    showExistingDocumentsOption={
                      filterSelectableFiles(
                        thirdPartyFiles,
                        configuredDocument.categoryId,
                        configuredDocument.verificationDateConfig,
                        false
                      ).length > 0
                    }
                  />
                );
              })}
            </>
          )}
          {documentsConfig.isOptionalDocumentsForThirdParty && (
            <>
              <Stroke />
              <MultipleUploadOption
                key="optional"
                label="Optional documents"
                required={false}
                showSidebar={true}
                maxUploads={documentsConfig.maxOptionalDocuments}
                valid={isOptionalValid}
                documents={filterForOptionalDocumentCardsList(documents)}
                onExistingDocumentUpload={() =>
                  handleSelectModalForOptionalDocuments(
                    documentsConfig.maxOptionalDocuments
                  )
                }
                onNewDocumentUpload={() =>
                  handleNewDocumentUploadForOptionalDocuments(
                    documentsConfig.maxOptionalDocuments
                  )
                }
                onRemoveDocument={(id) =>
                  onDeleteDocument(id, selectedEntityId, true)
                }
                showExistingDocumentsOption={
                  filterSelectableFiles(thirdPartyFiles, undefined, null, true)
                    .length > 0
                }
              />
            </>
          )}
        </>
      )}
      {isRelatedEntitySelected && (
        <>
          {documentsConfig.isRequiredDocumentsForRelatedEntities && (
            <>
              {relatedEntityConfiguredDocuments.map((configuredDocument) => {
                return (
                  <MultipleUploadOption
                    key={configuredDocument.categoryId}
                    label={getLabelByCategoryId(
                      categories,
                      configuredDocument.categoryId
                    )}
                    required={isIndividualRelatedEntityDocumentRequiredDependingOnAge(
                      selectedRelatedEntity,
                      configuredDocument
                    )}
                    showSidebar={true}
                    maxUploads={configuredDocument.maxUploads}
                    valid={isValid(configuredDocument.categoryId)}
                    documents={filterForRequiredDocumentCardsList(
                      documents,
                      selectedEntityId,
                      configuredDocument.categoryId,
                      true
                    )}
                    onExistingDocumentUpload={() =>
                      handleSelectModalForRequiredDocuments(
                        configuredDocument.categoryId,
                        configuredDocument.maxUploads,
                        configuredDocument.verificationDateConfig,
                        true
                      )
                    }
                    onNewDocumentUpload={() => {
                      handleNewDocumentUploadForRequiredDocuments(
                        configuredDocument.categoryId,
                        configuredDocument.maxUploads,
                        configuredDocument.verificationDateConfig,
                        true
                      );
                    }}
                    onRemoveDocument={(id) =>
                      onDeleteDocument(id, selectedEntityId, false)
                    }
                    showExistingDocumentsOption={
                      filterSelectableFiles(
                        thirdPartyFiles,
                        configuredDocument.categoryId,
                        configuredDocument.verificationDateConfig,
                        false
                      ).length > 0
                    }
                  />
                );
              })}
            </>
          )}
        </>
      )}
      {documentUploadModalState.isOpen && (
        <DocumentUploadModal
          thirdPartyId={thirdPartyId}
          categoryId={documentUploadModalState.categoryId}
          entityType={selectedEntityType}
          isOpen={true}
          onClose={() => {
            setDocumentUploadModalState(defaultUploadModalState);
          }}
          onSuccessDocumentUpload={(fileInfo) =>
            handleSuccessDocumentUpload(
              fileInfo,
              selectedEntityId,
              documentUploadModalState.isRelatedEntityDocument,
              documentUploadModalState.isOptional
            )
          }
          verificationDateConfig={
            documentUploadModalState.verificationDateConfig
          }
        />
      )}
      {selectModalState.isOpen && (
        <DocumentSelectModal
          thirdPartyId={thirdPartyId}
          maxUploads={getRemainingUploads(
            selectModalState.maxUploads,
            selectModalState,
            selectModalState.isOptional
          )}
          isOpen={true}
          selectableDocuments={filterSelectableFiles(
            thirdPartyFiles,
            selectModalState.categoryId,
            selectModalState.verificationDateConfig,
            selectModalState.isOptional
          )}
          onClose={() => setSelectModalState(defaultSelectModalState)}
          onAttachDocuments={(documents) => {
            handleAttachDocuments(
              documents,
              selectedEntityId,
              selectModalState.isRelatedEntityDocument
            );
          }}
        />
      )}
    </>
  );
};

export default MultipleUploadOptionsSection;
