export const headings = [
  {
    name: 'Third Party',
    width: '16%',
    orderable: true,
    filter: {
      title: 'Filter by type',
      field: 'thirdPartyTypes',
      options: [
        { name: 'Company', value: 'Business' },
        { name: 'Individual', value: 'Individual' },
      ],
      height: '220px',
    },
    field: 'Name',
  },
  { name: 'ID', width: '8%' },
  {
    name: 'Country',
    filter: {
      title: 'Filter by countries',
      field: 'thirdPartyCountryCodes',
      options: [],
      width: '400px',
      height: 'min(50vh, 32rem)',
    },
    width: '11%',
  },
  { name: 'Related', width: '8%' },
  {
    name: 'Due Diligence',
    width: '11.5%',
    filter: {
      title: 'Filter by dd type',
      field: 'thirdPartyDueDiligenceTypes',
      options: [
        { name: 'Screening', value: 'Screening' },
        { name: 'Due Diligence', value: 'Due Diligence' },
        // { name: 'UBO', value: 'UBO' },
      ],
      height: '180px',
    },
  },
  {
    name: 'Approval Status',
    width: '11.5%',
    orderable: false,
    filter: {
      title: 'Filter by approval status',
      field: 'approvalStatus',
      options: [
        { name: 'Approved', value: 'Approved' },
        { name: 'Rejected', value: 'Rejected' },
        { name: 'To be Determined', value: 'To be Determined' },
        { name: 'None', value: 'None' },
      ],
      height: '280px',
    },
  },
  {
    name: 'Risk Rating',
    width: '15%',
    orderable: true,
    field: 'riskRating',
    filter: {
      title: 'Filter by risk rating',
      field: 'riskRating',
      options: [
        { name: 'Low', value: 'Low' },
        { name: 'Medium', value: 'Medium' },
        { name: 'High', value: 'High' },
        { name: 'None', value: 'None' },
      ],
      height: '250px',
    },
  },
  {
    name: 'Internal Owners',
    width: '18.5%',
    orderable: false,
  },
  {
    name: 'Creation Date',
    orderable: true,
    field: 'CreatedDate',
    width: '12.5%',
  },
  {
    name: 'Next Review Date',
    orderable: true,
    field: 'NextReviewDate',
    width: '13.5%',
  },
  { name: '', width: '4.5%' },
];
