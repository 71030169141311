import styled from 'styled-components';

export const ProfileContainer = styled.div`
  background-color: ${({ theme }) => theme.colours.white};
  border-radius: ${({ theme }) => theme.pxToRem(5)};
  display: flex;
`;

export const MessageContainer = styled.div`
  color: ${({ theme }) => theme.colours.black};
  padding: ${({ theme }) => theme.pxToRem(30)};
`;
