import React, { FC, useState, useMemo, ChangeEvent } from 'react';
import {
  ComboBoxContainer,
  DocumentUploadsContainer,
  DocumentsInfoText,
  DocumentsSectionHeading,
  ErrorContainer,
} from './MultipleDocumentUploadWrapper.styles';

import { MultipleDocumentUploadWrapperTypes } from './types';
import { ThirdPartyType } from 'types/thirdPartyType';
import {
  DocumentData,
  FileInformationResponse,
  fileInformationResponseToDocumentData,
  isIndividualRelatedEntityDocumentRequiredDependingOnAge,
} from 'helpers/documentHelper';
import FieldSelect from 'components/atoms/field-select';
import { useTheme } from 'styled-components';
import { Icon, Loading, Typography } from 'components/atoms';
import useFetch from 'hooks/useFetch';
import { useConfig } from 'config';
import { useAuth } from 'auth';
import { LoadingContainer } from '../document-upload-modal/DocumentUploadModal.styles';
import MultipleUploadOptionsSection from './MultipleUploadOptionsSection';

const MultipleDocumentUploadWrapper: FC<MultipleDocumentUploadWrapperTypes> = ({
  thirdPartyId,
  thirdPartyData,
  categories,
  selectedRelatedEntities,
  documentsConfig,
  error,
  isValid,
  value,
  onChange,
}: MultipleDocumentUploadWrapperTypes) => {
  const theme = useTheme();
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const errorMessage = error?.message;

  const [selectedThirdPartyOrEntityId, setSelectedThirdPartyOrEntityId] =
    useState<number>(thirdPartyId);
  const [isThirdPartySelected, setIsThirdPartySelected] = useState<boolean>(
    documentsConfig.isRequiredDocumentsForThirdParty ||
      documentsConfig.isOptionalDocumentsForThirdParty
  );

  const thirdPartyAndEntitiesSelection = useMemo(() => {
    if (!documentsConfig || !thirdPartyData) {
      return [];
    }

    let options: { name: string; value: string }[] = [];

    if (
      documentsConfig.isRequiredDocumentsForThirdParty ||
      documentsConfig.isOptionalDocumentsForThirdParty
    ) {
      options = [
        ...options,
        {
          name: thirdPartyData.name + ' (This Thirdparty)',
          value: thirdPartyData.id.toString(),
        },
      ];
    }

    if (documentsConfig.isRequiredDocumentsForRelatedEntities) {
      const relatedEntitiesOptions = selectedRelatedEntities
        .filter((selectedRelatedEntity) => {
          if (
            selectedRelatedEntity.type === ThirdPartyType.Business &&
            documentsConfig.relatedEntitiesDocuments
              .relatedEntitiesConfiguredDocumentsBusiness.length > 0
          ) {
            return true;
          }
          if (
            selectedRelatedEntity.type === ThirdPartyType.Individual &&
            documentsConfig.relatedEntitiesDocuments
              .relatedEntitiesConfiguredDocumentsIndividual.length > 0
          ) {
            return true;
          }

          return false;
        })
        .map((selectedRelatedEntity) => ({
          name: selectedRelatedEntity.name,
          value: selectedRelatedEntity.id.toString(),
        }));

      const sortedREs = relatedEntitiesOptions.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      options = [...options, ...sortedREs];
    }
    return options;
  }, [thirdPartyData, selectedRelatedEntities, documentsConfig]);

  const totalRequiredDocumentsCount = useMemo(() => {
    let count = 0;

    const selectedIndividualRelatedEntities = selectedRelatedEntities.filter(
      ({ type }) => type === ThirdPartyType.Individual
    );

    const selectedBusinessRelatedEntities = selectedRelatedEntities.filter(
      ({ type }) => type === ThirdPartyType.Business
    );

    if (thirdPartyData.type === ThirdPartyType.Individual) {
      count +=
        documentsConfig.thirdPartyDocuments
          .thirdPartyConfiguredDocumentsIndividual.length;
    } else {
      count +=
        documentsConfig.thirdPartyDocuments
          .thirdPartyConfiguredDocumentsBusiness.length;
    }

    if (documentsConfig.isRequiredDocumentsForRelatedEntities) {
      const individualRequiredDocumentsCount =
        selectedIndividualRelatedEntities.reduce((acc, relatedEntity) => {
          const requiredDocuments =
            documentsConfig.relatedEntitiesDocuments.relatedEntitiesConfiguredDocumentsIndividual.filter(
              (configuredDoc) =>
                isIndividualRelatedEntityDocumentRequiredDependingOnAge(
                  relatedEntity,
                  configuredDoc
                )
            );

          return acc + requiredDocuments.length;
        }, 0);
      const businessRequiredDocumentsCount =
        selectedBusinessRelatedEntities.length *
        documentsConfig.relatedEntitiesDocuments
          .relatedEntitiesConfiguredDocumentsBusiness.length;

      count +=
        individualRequiredDocumentsCount + businessRequiredDocumentsCount;
    }

    return count;
  }, [thirdPartyData.type, documentsConfig, selectedRelatedEntities]);

  const { data: filesData, loading: filesDataLoading } = useFetch<
    FileInformationResponse[]
  >(`${vantageWebApi}/thirdparty/${thirdPartyId}/files`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const existingThirdPartyFiles = useMemo(() => {
    if (!filesData) {
      return [];
    }
    return filesData.map((fileInfo) =>
      fileInformationResponseToDocumentData(fileInfo)
    );
  }, [filesData]);

  const handleAddDocuments = (
    documents: DocumentData[],
    newDocuments: DocumentData[]
  ) => {
    onChange([...documents, ...newDocuments]);
  };

  const handleRemoveDocument = (
    documents: DocumentData[],
    id: string,
    entityId: number,
    isOptional: boolean
  ) => {
    const foundIndex = documents.findIndex(
      (doc) =>
        doc.id === id &&
        doc.entityId === entityId &&
        doc.isOptionalDocument === isOptional
    );
    documents.splice(foundIndex, 1);
    const copy = [...documents];
    onChange(copy);
  };

  return (
    <>
      {filesDataLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <DocumentUploadsContainer>
          <DocumentsSectionHeading>Document upload</DocumentsSectionHeading>
          {selectedRelatedEntities.length > 0 && (
            <>
              <DocumentsInfoText>
                {`You have ${totalRequiredDocumentsCount} documents to upload for all related entities created`}
              </DocumentsInfoText>
              <ComboBoxContainer>
                <FieldSelect
                  valid={isValid}
                  fullWidth
                  placeholder=""
                  ariaLabel="Document Category Dropdown"
                  id="ThirdPartyAndEntities"
                  options={thirdPartyAndEntitiesSelection}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setSelectedThirdPartyOrEntityId(
                      parseInt(e.target.value, 10)
                    );
                    setIsThirdPartySelected(e.target.selectedIndex === 0);
                  }}
                />
              </ComboBoxContainer>
            </>
          )}

          <MultipleUploadOptionsSection
            documentsConfig={documentsConfig}
            documents={value}
            categories={categories}
            thirdPartyId={thirdPartyData.id}
            thirdPartyType={thirdPartyData.type as ThirdPartyType}
            thirdPartyFiles={existingThirdPartyFiles}
            selectedRelatedEntities={selectedRelatedEntities}
            selectedEntityId={selectedThirdPartyOrEntityId}
            isThirdPartySelected={isThirdPartySelected}
            onSuccessDocumentUpload={(document) => {
              handleAddDocuments(value, [document]);
            }}
            onDeleteDocument={(id, entityId, isOptional) => {
              handleRemoveDocument(value, id, entityId, isOptional);
            }}
            onAttachDocuments={(documents) => {
              handleAddDocuments(value, documents);
            }}
            error={error}
          />

          {errorMessage && (
            <ErrorContainer>
              <Icon
                className="risk-icon"
                icon="risk"
                size="xxs"
                colour={theme.colours.redRampage}
              />
              <Typography value={errorMessage} tag="p" />
            </ErrorContainer>
          )}
        </DocumentUploadsContainer>
      )}
    </>
  );
};

export default MultipleDocumentUploadWrapper;
