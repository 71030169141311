import { GatewaySubscriptionTypeNames } from 'auth/constants/subscriptions';

const stateMap = {
  Archived: 'Archived',
  Errored: 'Errored',
  InProgress: 'In progress',
  New: 'In progress',
};

const statusMapping = {
  Incomplete: 'Incomplete',
  CompleteAllMatchesDiscarded: 'Complete - All matches discarded',
  CompletePossibleMatchesFound: 'Complete - Possible matches',
  CompleteMatchesFound: 'Complete - Confirmed matches',
  CompleteNoMatchesFound: 'Complete - No matches found',
  Complete: 'Complete',
};

export const statusMapper = (
  status: string,
  state: string,
  dueDiligenceType: string,
  reportStatus?: string
) => {
  const isSelfService =
    dueDiligenceType === 'Self Service' ||
    dueDiligenceType === GatewaySubscriptionTypeNames.SelfServiceScreening;
  const isSelfServiceAndReportStatusNotSet = !reportStatus || isSelfService;

  if (state == 'New' && isSelfService) {
    return state;
  }

  if (state in stateMap) {
    return stateMap[state];
  }

  const mapState = (currentStatus) => {
    if (state === 'UnresolvedMatches') {
      return isSelfService
        ? 'Incomplete - (Unresolved matches)'
        : reportStatus === null
        ? 'In progress'
        : statusMapper(reportStatus, null, 'Managed Service');
    } else if (state === 'UnresolvedAlerts') {
      return isSelfService
        ? 'Incomplete - (Monitoring alert)'
        : statusMapper(reportStatus, null, 'Managed Service');
    } else {
      return currentStatus;
    }
  };

  let statusToMap = statusMapping[status] || status;

  if (statusToMap == 'Incomplete' || isSelfServiceAndReportStatusNotSet) {
    return mapState(statusToMap);
  } else {
    return mapState(reportStatus);
  }
};
