import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useTerminologyConfig } from 'context/terminologyConfig';
import { TimelineCard } from 'components/molecules';
import { TimelineCarousel } from 'components/organisms';
import { useFetch } from 'hooks';
import { MultiSelect } from 'components/atoms';
import { useParams } from 'react-router';
import { auditEvents } from './AuditEventsConstants';

const entityTypes = ['Individual', 'Business'];

const dueDiligenceTypes = [
  {
    value: 'SelfServiceScreening',
    name: 'Self Service Screening',
  },
  {
    value: 'ManagedServiceScreening',
    name: 'Managed Service Screening',
  },
  {
    value: 'ManagedServiceDueDiligence',
    name: 'Managed Service Due Diligence',
  },
];

const Audit = () => {
  const { accessToken } = useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const { id } = useParams<{ id: string }>();
  const [filteredEntity, setFilteredEntity] = useState([]);
  const [filteredAuditEvent, setFilteredAuditEvent] = useState([]);
  const [filteredDueDiligence, setFilteredDueDiligence] = useState([]);
  const [filteredPerformedBy, setFilteredPerformedBy] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageData, setPageData] = useState({
    results: [],
    currentPage: 1,
    pageCount: 0,
    rowCount: 0,
  });

  const { data, loading, error, trigger, responseStatus } = useFetch(
    `${vantageWebApi}/thirdparty/search-audit?thirdPartyId=${id}`,
    {
      method: 'POST',
      lazy: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const handleTrigger = useCallback(
    (page) => {
      trigger({
        body: JSON.stringify({
          page: page,
          pageSize: 6,
          filter: {
            auditEvent: filteredAuditEvent,
            dueDiligenceType: filteredDueDiligence,
            performedBy: filteredPerformedBy,
            entity: filteredEntity,
          },
        }),
      });
    },
    [
      filteredAuditEvent,
      filteredDueDiligence,
      filteredPerformedBy,
      filteredEntity,
      trigger,
    ]
  );

  useEffect(() => {
    handleTrigger(currentPage);
  }, [currentPage, handleTrigger]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    filteredAuditEvent,
    filteredDueDiligence,
    filteredPerformedBy,
    filteredEntity,
  ]);

  useEffect(() => {
    if (data) {
      setPageData(data);
    }
  }, [data]);

  const { data: users } = useFetch(`${vantageWebApi}/account/team`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const parseAction = (action) => {
    if (action.includes('Third Party')) {
      return action.replace(
        'Third Party',
        terminologyConfig?.thirdPartySingularNaming
      );
    }
    return action;
  };

  const multiSelectUpdate = (state, setState, data) =>
    setState(
      data.checked
        ? [...state, data.value]
        : state.filter((item) => item !== data.value)
    );

  const filters = () => (
    <>
      <MultiSelect
        dark
        label="Entity"
        defaultValues={filteredEntity}
        items={entityTypes.map((entity) => ({
          value: entity,
          name: entity,
        }))}
        onChange={(filter) =>
          multiSelectUpdate(filteredEntity, setFilteredEntity, filter)
        }
      />
      <MultiSelect
        dark
        label="Audit event"
        defaultValues={filteredAuditEvent}
        items={auditEvents.map((event) => ({
          value: event,
          name: parseAction(event),
        }))}
        onChange={(filter) =>
          multiSelectUpdate(filteredAuditEvent, setFilteredAuditEvent, filter)
        }
      />
      <MultiSelect
        dark
        label="Due diligence type"
        items={dueDiligenceTypes}
        onChange={(filter) =>
          multiSelectUpdate(
            filteredDueDiligence,
            setFilteredDueDiligence,
            filter
          )
        }
      />
      <MultiSelect
        dark
        label="Performed by"
        items={[
          { value: 'Control Risks', name: 'Control Risks' },
          { value: 'Monitoring', name: 'Monitoring' },
          ...(users && users.length > 0
            ? users?.map((user) => ({
                value: user.firstName,
                name: `${user.firstName} ${user.lastName}`,
              }))
            : []),
        ]}
        onChange={(filter) =>
          multiSelectUpdate(filteredPerformedBy, setFilteredPerformedBy, filter)
        }
      />
    </>
  );

  return (
    <TimelineCarousel
      uniqueId="third-party-audit-timeline"
      filters={filters()}
      loading={loading}
      error={error || (responseStatus && responseStatus !== 200)}
      onPageChange={setCurrentPage}
      currentPage={pageData.currentPage}
      totalPages={pageData.pageCount}
      totalItems={pageData.rowCount}
    >
      {pageData.results?.map((item, index) => (
        <TimelineCard
          key={`audit-${index}`}
          content={JSON.parse(item.content)}
          action={parseAction(item.auditEvent)}
          dateTime={item.performedDate}
          name={item?.name}
          screeningType={item?.dueDiligenceType}
          type={item?.type}
          performedBy={item?.performedBy}
          screeningLevel={JSON.parse(item.content)?.ReportLevel || ''}
        />
      ))}
    </TimelineCarousel>
  );
};

export default Audit;
