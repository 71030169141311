import styled from 'styled-components';

export const MessageCarouselComponent = styled.div`
  padding: ${({ theme }) => theme.spacing(40)};
  border-radius: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.colours.fluorescentOrange};
  box-shadow: 0 ${({ theme }) => theme.pxToRem(10)}
    ${({ theme }) => theme.pxToRem(30)} 0 rgba(0, 0, 0, 0.05);
`;

export const MessageCarouselHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const MessageCarouselIndicator = styled.span`
  flex-shrink: 0;
  width: ${({ theme }) => theme.pxToRem(23)};
  height: ${({ theme }) => theme.pxToRem(23)};
  margin-right: ${({ theme }) => theme.spacing(10)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colours.spicedPurple};
  color: ${({ theme }) => theme.colours.white};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }) => theme.pxToRem(23)};
  font-weight: bold;
  text-align: center;
`;

export const MessageCarouselSlider = styled.div`
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing(30)};
  border-radius: ${({ theme }) => theme.spacing(5)};

  .swiper-slide {
    height: auto;

    > * {
      height: 100%;
    }
  }
`;
