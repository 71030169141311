import React from 'react';
import { TerminologyConfigProvider } from './terminologyConfig/index';
import { CountriesProvider } from './countries/index';

//combining information providers
const DataProviders = ({ children }) => {
  return (
    <TerminologyConfigProvider>
      <CountriesProvider>{children}</CountriesProvider>
    </TerminologyConfigProvider>
  );
};

export default DataProviders;
