import React, { useContext, useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useFetch } from 'hooks/useFetch';
import { useConfig } from 'config';
import { useAuth } from 'auth';
import { useTerminologyConfig } from 'context/terminologyConfig';

import { Redirect, useHistory } from 'react-router-dom';
import { GatewayPermissions } from 'auth/constants/permissions';
import * as yup from 'yup';

import Button from 'components/atoms/button';
import { Accordion, FormControl, Loading, Typography } from 'components/atoms';
import Select from 'components/atoms/field-select';
import Input from 'components/atoms/field-input';
import TextArea from 'components/atoms/field-textarea';
import { Icon, FieldDate } from 'components/atoms';

import {
  Wrapper,
  FieldsContainer,
  FormHeader,
  ButtonWrapper,
  ThirdPartyError,
} from '../form.styles';
import {
  ActionButton,
  ArrayItem,
  DocumentGridContainer,
  DocumentWrapper,
  CardDeleteButton,
  InfoLeftContainer,
} from './step3.styles';

import { FormContext } from 'context/form';

import { yupResolver } from '@hookform/resolvers/yup';
import { phoneNumber } from 'helpers/yup-validations';
import { ThirdPartyType } from 'types/thirdPartyType';
import DocumentCard from 'components/molecules/document-card';
import {
  DocumentData,
  FileFormat,
  fileInformationResponseToDocumentData,
} from 'helpers/documentHelper';
import DocumentUploadModal from 'components/organisms/document-upload-modal';
import ConfirmationModal from 'components/organisms/confirmation-modal';
import { GatewaySubscriptionTypes } from 'auth/constants/subscriptions';
import { setNullWhenIsEmpty } from 'helpers/strings-functions';

const Step3 = () => {
  const [ownerIdsList, setOwnerIdsList] = useState([]);
  const { accessToken, gatewayUserId, hasPermission, hasSubscription } =
    useAuth();
  const terminologyConfig = useTerminologyConfig();
  const { vantageWebApi } = useConfig();
  const {
    formState: { formData, thirdPartyDataConfig },
    formDispatch,
  } = useContext(FormContext);
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const [
    isInitiateComplianceCheckModalOpen,
    setIsInitiateComplianceCheckModalOpen,
  ] = useState(false);
  const history = useHistory();

  const canUploadDocuments: boolean = hasPermission(
    GatewayPermissions.UploadDocuments
  );

  const canInitiateComplianceCheck =
    (hasSubscription(GatewaySubscriptionTypes.SelfServiceScreening) &&
      hasPermission(
        GatewayPermissions.InitiateDueDiligenceSelfServiceScreening
      )) ||
    (hasSubscription(GatewaySubscriptionTypes.ManagedServiceScreening) &&
      hasPermission(
        GatewayPermissions.InitiateDueDiligenceManagedServiceScreening
      )) ||
    (hasSubscription(GatewaySubscriptionTypes.ManagedServiceDueDiligence) &&
      hasPermission(GatewayPermissions.OrderManagedDueDiligence)) ||
    (hasSubscription(GatewaySubscriptionTypes.SelfServiceUBO) &&
      hasPermission(GatewayPermissions.OrderOwnershipManagedDueDiligence));

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        ownerIds: yup
          .array()
          .test(
            'duplicatedOwnerIds',
            `The same user has been selected as an ${terminologyConfig?.internalOwnerSingularNaming} more than once. Please remove duplication`,
            (ownerIds) => {
              const ownerIdsToCheck: string[] = ownerIds
                .filter(({ value }) => value !== 'DEFAULT')
                .map((obj) => obj.value);

              return new Set(ownerIdsToCheck).size === ownerIdsToCheck.length;
            }
          ),
        documents: yup
          .array()
          .of(yup.object())
          .test(
            'unique-names',
            'You have two or more files uploaded with the same name, please remove duplication',
            (value) => {
              if (!value) return true;

              const documentNames = new Set();

              for (const document of value) {
                if (documentNames.has(document.name)) {
                  return false;
                }
                documentNames.add(document.name);
              }
              return true;
            }
          ),
        nextReviewDate: yup
          .date()
          .nullable()
          .when([], {
            is: () =>
              thirdPartyDataConfig.nextReviewDate.isEnabled &&
              thirdPartyDataConfig.nextReviewDate.isMandatory,
            then: (schema) =>
              schema.required(`Please enter a next review date`),
          }),
        referenceField1: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField1.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        referenceField2: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField2.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        referenceField3: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.referenceField3.isMandatory,
            then: (schema) => schema.required(`Please enter a reference`),
          }),
        keyFullName: yup
          .string()
          .max(40, 'Maximum 40 characters')
          .nullable()
          .when([], {
            is: () =>
              isBusiness && thirdPartyDataConfig.keyFullName.isMandatory,
            then: (schema) => schema.required(`Please enter a contact name`),
          }),
        keyEmail: yup
          .string()
          .max(80, 'Maximum 80 characters')
          .email('You must enter a valid email address')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.keyEmail.isMandatory,
            then: (schema) => schema.required(`Please enter a contact email`),
          }),
        keyPhoneNumber: phoneNumber.max(20, 'Maximum 20 characters').when([], {
          is: () => thirdPartyDataConfig.keyPhoneNumber.isMandatory,
          then: (schema) => schema.required(`Please enter a contact phone`),
        }),
        internalNotes: yup
          .string()
          .max(2000, 'Maximum 2000 characters')
          .nullable()
          .when([], {
            is: () => thirdPartyDataConfig.internalNotes.isMandatory,
            then: (schema) => schema.required(`Please enter internal notes`),
          }),
      })
    ),
    defaultValues: {
      ownerIds: [],
      nextReviewDate: null,
      referenceField1: null,
      referenceField2: null,
      referenceField3: null,
      keyFullName: null,
      keyPhoneNumber: null,
      keyEmail: null,
      internalNotes: null,
      documents: [],
    },
  });

  const {
    fields: ownerFields,
    append: ownerAppend,
    prepend: ownerPrepend,
    remove: ownerRemove,
  } = useFieldArray({
    control,
    name: 'ownerIds',
  });

  const {
    fields: documentsFields = [],
    append: documentsFieldsAppend,
    remove: documentsFieldsRemove,
  } = useFieldArray({
    control,
    name: 'documents',
    keyName: 'fieldId',
  });

  const [documentCount, setDocumentCount] = useState(documentsFields.length);
  const defaultOwnerId =
    thirdPartyDataConfig.autoAssignDefaultInternalOwner.configurationValue;

  const parseOwnerids = (ids: any[]) => {
    if (ids && ids.length > 0) {
      const filtered = ids
        .filter(({ value }) => value !== 'DEFAULT')
        .map(({ value }) => parseInt(value));
      return filtered;
    }
    return [];
  };

  const {
    data: createThirdPartyData,
    error: createThirdPartyError,
    loading: createThirdPartyLoading,
    trigger: createThirdPartyFetchLazy,
  } = useFetch(`${vantageWebApi}/thirdParty/create`, {
    method: 'POST',
    lazy: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    onSuccess: () =>
      canInitiateComplianceCheck && setIsInitiateComplianceCheckModalOpen(true),
  });

  const { loading: ownerIdsLoading } = useFetch(
    `${vantageWebApi}/account/team`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      onSuccess: (ownerIds) => {
        setOwnerIdsList(ownerIds);
      },
    }
  );

  useEffect(() => {
    if (ownerIdsList && ownerIdsList.length > 0) {
      if (defaultOwnerId && gatewayUserId != defaultOwnerId) {
        ownerPrepend([{ value: defaultOwnerId }, { value: gatewayUserId }]);
      } else {
        ownerPrepend([{ value: gatewayUserId }]);
      }
    }
  }, [ownerIdsList, gatewayUserId, ownerPrepend, defaultOwnerId]);

  const isBusiness = formData?.type === ThirdPartyType.Business;

  const permissionToRemoveThemselvesEnabled = (ownerId: string) => {
    if (
      !thirdPartyDataConfig.allowRemoveMyselfAsOwner.isEnabled &&
      ownerId == gatewayUserId
    ) {
      return false;
    }

    return true;
  };

  const isDefaultOwner = (ownerId: string, index: number) => {
    if (ownerId == defaultOwnerId && index === 0) {
      return true;
    }
    return false;
  };

  const showAddOwnersButton =
    thirdPartyDataConfig.allowAddOtherOwners.isEnabled &&
    ownerFields.length < 3;

  const onSubmit = (data) => {
    formDispatch({
      type: 'SAVE_FORM_DATA',
      payload: {
        form: {
          ...data,
          keyEmail: setNullWhenIsEmpty(data?.keyEmail),
          keyPhoneNumber: setNullWhenIsEmpty(data?.keyPhoneNumber),
        },
      },
    });
    createThirdPartyFetchLazy({
      body: JSON.stringify({
        ...formData,
        ...data,
        name: formData?.name?.trim(),
        alternativeName: formData?.alternativeName?.trim(),
        internalId: formData?.internalId?.trim(),
        duns: formData?.duns || null,
        primaryCountry:
          formData?.primaryCountry === 'DEFAULT'
            ? null
            : formData?.primaryCountry,
        keyPhoneNumber:
          data?.keyPhoneNumber?.toString()?.length > 0
            ? data?.keyPhoneNumber?.toString()
            : null,
        countryTwo:
          formData?.countryTwo === 'DEFAULT' ? null : formData?.countryTwo,
        countryThree:
          formData?.countryThree === 'DEFAULT' ? null : formData?.countryThree,
        nationality:
          formData.nationality === 'DEFAULT' ? null : formData?.nationality,
        companyUrl: formData?.companyUrl,
        ownerIds: parseOwnerids(data.ownerIds),
        dateOfBirth: formData?.dateOfBirth
          ? new Date(formData?.dateOfBirth).toLocaleDateString('fr-CA') // we are using fr-CA to establish format yyyy-mm-dd since we were usig ISO standards
          : null,
        nextReviewDate:
          thirdPartyDataConfig.nextReviewDate.isEnabled && data?.nextReviewDate
            ? new Date(data?.nextReviewDate).toLocaleDateString('fr-CA')
            : null,
        documentIds: data?.documents?.map((document) => document.id),
      }),
    });
  };

  const goBack = () =>
    formDispatch({
      type: 'UPDATE_ACTIVE_STEP',
      payload: { activeStep: 2 },
    });

  const handleInitiateComplianceCheckOnCancel = () => {
    history.push(`/third-party/profile/${createThirdPartyData?.id}`);
  };

  const handleInitiateComplianceCheckOnProceed = () => {
    history.push(`/initiate-due-diligence/${createThirdPartyData?.id}`);
  };

  const isValid = (field: string) => {
    return !errors?.[field]?.message && !touchedFields?.[field]?.message;
  };

  if (createThirdPartyData?.id && !canInitiateComplianceCheck) {
    return <Redirect to={`/third-party/profile/${createThirdPartyData?.id}`} />;
  }

  return (
    <>
      <Wrapper>
        <FormHeader>{`Please add additional information:`}</FormHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            title={`${terminologyConfig?.internalOwnerPluralNaming}`}
            showCaret={false}
            isOpen={true}
          >
            <FieldsContainer>
              {ownerIdsLoading ? (
                <Loading />
              ) : (
                <FormControl>
                  {ownerFields.map(({ id, value }, index) => {
                    return (
                      <ArrayItem key={id}>
                        <FormControl
                          errorMessage={(errors?.ownerIds as any)?.message}
                          style={{ flex: 1 }}
                        >
                          <Select
                            label={`${terminologyConfig?.internalOwnerSingularNaming}`}
                            key={`ownerIds.${index}.value`}
                            id={`ownerIds.${index}.value`}
                            register={register}
                            options={ownerIdsList?.map((owner) => ({
                              name: `${owner.firstName} ${owner.lastName}${
                                owner.company ? ` (${owner.company})` : ''
                              }`,
                              value: owner.id,
                            }))}
                            disabled={
                              !permissionToRemoveThemselvesEnabled(value) ||
                              isDefaultOwner(value, index)
                            }
                            placeholder={`Please select an ${terminologyConfig?.internalOwnerSingularNaming.toLowerCase()}`}
                            placeholderValue="DEFAULT"
                            placeholderDisabled
                            fullWidth
                          />
                        </FormControl>
                        {permissionToRemoveThemselvesEnabled(value) &&
                          !isDefaultOwner(value, index) && (
                            <ActionButton
                              type="button"
                              onClick={() => ownerRemove(index)}
                            >
                              <Icon icon="trash" size="xs" />
                            </ActionButton>
                          )}
                      </ArrayItem>
                    );
                  })}
                  {showAddOwnersButton && (
                    <ActionButton
                      type="button"
                      className="bold"
                      onClick={() => ownerAppend({ value: 'DEFAULT' })}
                    >
                      <Icon className="icon" icon="plus-circle" size="xs" />
                      Add another{' '}
                      {terminologyConfig?.internalOwnerSingularNaming}
                    </ActionButton>
                  )}
                </FormControl>
              )}
            </FieldsContainer>
          </Accordion>
          {thirdPartyDataConfig.nextReviewDate.isEnabled && (
            <Accordion title="Next Review Date" showCaret={false} isOpen={true}>
              <FieldsContainer>
                <FormControl errorMessage={errors?.nextReviewDate?.message}>
                  <Controller
                    control={control}
                    name="nextReviewDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FieldDate
                          label="Next review date"
                          value={value}
                          onChange={(date) => {
                            onChange(date ? new Date(date) : null);
                          }}
                          format="DD/MM/YYYY"
                          fullWidth
                          valid={isValid('nextReviewDate')}
                          required={
                            thirdPartyDataConfig.nextReviewDate.isMandatory
                          }
                        />
                      );
                    }}
                  />
                </FormControl>
              </FieldsContainer>
            </Accordion>
          )}
          <Accordion title="Reference Fields" showCaret={false} isOpen={true}>
            <FieldsContainer>
              <FormControl errorMessage={errors.referenceField1?.message}>
                <Input
                  label={thirdPartyDataConfig.referenceField1.customName}
                  id="referenceField1"
                  name="referenceField1"
                  register={register}
                  fullWidth
                  valid={isValid('referenceField1')}
                  required={thirdPartyDataConfig.referenceField1.isMandatory}
                />
              </FormControl>
              <FormControl errorMessage={errors.referenceField2?.message}>
                <Input
                  label={thirdPartyDataConfig.referenceField2.customName}
                  id="referenceField2"
                  name="referenceField2"
                  register={register}
                  fullWidth
                  valid={isValid('referenceField2')}
                  required={thirdPartyDataConfig.referenceField2.isMandatory}
                />
              </FormControl>
              <FormControl errorMessage={errors.referenceField3?.message}>
                <Input
                  label={thirdPartyDataConfig.referenceField3.customName}
                  id="referenceField3"
                  name="referenceField3"
                  register={register}
                  fullWidth
                  valid={isValid('referenceField3')}
                  required={thirdPartyDataConfig.referenceField3.isMandatory}
                />
              </FormControl>
            </FieldsContainer>
          </Accordion>

          <Accordion
            title={`${terminologyConfig?.thirdPartySingularNaming} contact details`}
            showCaret={false}
            isOpen={true}
          >
            <FieldsContainer>
              {isBusiness && (
                <FormControl errorMessage={errors.keyFullName?.message}>
                  <Input
                    label="Key contact name"
                    id="keyFullName"
                    name="keyFullName"
                    register={register}
                    fullWidth
                    valid={isValid('keyFullName')}
                    required={thirdPartyDataConfig.keyFullName.isMandatory}
                  />
                </FormControl>
              )}
              <FormControl errorMessage={errors.keyPhoneNumber?.message}>
                <Input
                  label="Key contact phone number"
                  id="keyPhoneNumber"
                  name="keyPhoneNumber"
                  register={register}
                  fullWidth
                  type="tel"
                  valid={isValid('keyPhoneNumber')}
                  required={thirdPartyDataConfig.keyPhoneNumber.isMandatory}
                />
              </FormControl>

              <FormControl errorMessage={errors.keyEmail?.message}>
                <Input
                  type="email"
                  label="Key contact email address"
                  id="keyEmail"
                  name="keyEmail"
                  register={register}
                  fullWidth
                  valid={isValid('keyEmail')}
                  required={thirdPartyDataConfig.keyEmail.isMandatory}
                />
              </FormControl>
            </FieldsContainer>
          </Accordion>

          {canUploadDocuments && (
            <Accordion
              title={`${terminologyConfig?.thirdPartySingularNaming} documents`}
              showCaret={false}
              isOpen={true}
            >
              <InfoLeftContainer>
                <Typography
                  className="record-text"
                  value={`${documentsFields.length} records`}
                  tag="h6"
                />
              </InfoLeftContainer>
              <FieldsContainer maxWidth="100%">
                <FormControl errorMessage={(errors?.documents as any)?.message}>
                  <label>Upload Documents</label>
                  <Button
                    outline
                    small
                    type="button"
                    onClick={() => setDocumentUploadModalOpen(true)}
                    disabled={documentCount >= 60}
                  >
                    Upload Document
                  </Button>
                  <DocumentGridContainer>
                    {documentsFields &&
                      documentsFields.map(
                        (fieldDocument: DocumentData, index: number) => {
                          return (
                            <DocumentWrapper key={index}>
                              <DocumentCard
                                fileType={
                                  fieldDocument.type.toLowerCase() as FileFormat
                                }
                                name={fieldDocument.name}
                                date={new Date().toDateString()}
                                category={fieldDocument.category}
                                actionComponent={
                                  <CardDeleteButton
                                    type="button"
                                    aria-label="Close"
                                    onClick={() => {
                                      setDocumentCount(
                                        (documentsCount) => documentsCount - 1
                                      );
                                      documentsFieldsRemove(index);
                                    }}
                                  >
                                    <Icon
                                      icon="close"
                                      aria-hidden
                                      size="xxxxxs"
                                      cursor="pointer"
                                    />
                                  </CardDeleteButton>
                                }
                              />
                            </DocumentWrapper>
                          );
                        }
                      )}
                  </DocumentGridContainer>
                </FormControl>
              </FieldsContainer>
            </Accordion>
          )}

          <Accordion title="Internal Notes" showCaret={false} isOpen={true}>
            <FieldsContainer maxWidth="65%">
              <FormControl errorMessage={errors.internalNotes?.message}>
                <TextArea
                  label="Internal Notes"
                  id="internalNotes"
                  name="internalNotes"
                  register={register}
                  fullWidth
                  valid={isValid('internalNotes')}
                  required={thirdPartyDataConfig.internalNotes.isMandatory}
                />
              </FormControl>
            </FieldsContainer>
          </Accordion>

          {createThirdPartyError && (
            <ThirdPartyError>
              <p>An error has been occurred</p>
            </ThirdPartyError>
          )}
          <ButtonWrapper justifyContent="end">
            <Button text type="button" onClick={() => goBack()}>
              Back
            </Button>
            <Button
              type="submit"
              className="button-spinner"
              secondary
              disabled={createThirdPartyLoading || ownerIdsLoading}
            >
              {createThirdPartyLoading ? (
                <Loading />
              ) : (
                `Create ${terminologyConfig?.thirdPartySingularNaming}`
              )}
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
      {documentUploadModalOpen && (
        <DocumentUploadModal
          entityType={
            isBusiness ? ThirdPartyType.Business : ThirdPartyType.Individual
          }
          isOpen={documentUploadModalOpen}
          onClose={() => setDocumentUploadModalOpen(false)}
          onSuccessDocumentUpload={(fileInformation) => {
            setDocumentCount((prevCount) => prevCount + 1);
            documentsFieldsAppend(
              fileInformationResponseToDocumentData(fileInformation)
            );
          }}
        />
      )}
      <ConfirmationModal
        isOpen={isInitiateComplianceCheckModalOpen}
        onClose={() => setIsInitiateComplianceCheckModalOpen(false)}
        onProceed={handleInitiateComplianceCheckOnProceed}
        onCancel={handleInitiateComplianceCheckOnCancel}
        title="Initiate compliance check?"
        message="The third party has been successfully created. Would you like to initiate a compliance check?"
        proceedButtonText="Initiate"
        cancelButtonText="Not now"
        proceedButtonLoading={false}
        onProceedAriaLabel="Initiate"
        loading={false}
        cancelButtonProps={{ text: true, style: { width: 'fit-content' } }}
      />
    </>
  );
};

export default Step3;
