import React, { createContext, useContext, useMemo } from 'react';

import { useAuth } from 'auth';
import { useConfig } from 'config';
import { useFetch } from 'hooks';

import { TerminologyConfigContextType } from './types';
import LoadingContainer from 'context/Loading';

const defaultContext: TerminologyConfigContextType = {
  thirdPartySingularNaming: 'Third party',
  thirdPartyPluralNaming: 'Third parties',
  internalOwnerSingularNaming: 'Internal owner',
  internalOwnerPluralNaming: 'Internal owners',
  internalIdSingularNaming: 'Internal ID',
};

export const TerminologyConfigContext = createContext(defaultContext);

export const TerminologyConfigProvider = ({ children }) => {
  const { accessToken } = useAuth();
  const { vantageWebApi } = useConfig();
  const headers = useMemo(
    () => ({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  );

  const {
    loading,
    error,
    data: terminologyConfig,
  } = useFetch<TerminologyConfigContextType>(
    `${vantageWebApi}/client/naming-conventions`,
    {
      initialState: { loading: !!accessToken },
      lazy: !accessToken,
      method: 'GET',
      headers,
    }
  );

  if (error) {
    return;
  }
  return (
    <TerminologyConfigContext.Provider value={terminologyConfig}>
      {loading ? <LoadingContainer error={error} /> : children}
    </TerminologyConfigContext.Provider>
  );
};

/**
 * @function useTerminologyConfig
 * @description Custom hook for accessing the terminology configuration context.
 * Facilitates the reuse of the context in components and avoids repeated imports of useContext.
 * @returns {TerminologyConfigContextType} The current terminology configuration.
 */
export const useTerminologyConfig = () => useContext(TerminologyConfigContext);
