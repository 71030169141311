import { Dispatch, Reducer, useReducer } from 'react';
import { FetchAction, FetchActionTypes, FetchState } from './types';

const getDefaultState = <T, U>(): FetchState<T, U> => ({
  loading: false,
  data: null as T,
  error: false,
  responseStatus: null,
  errorData: null as U,
});

type ActionHandler<T, U> = (
  state: FetchState<T, U>,
  action: FetchAction<T, U>
) => FetchState<T, U>;

const fetchInit = <T, U>(state: FetchState<T, U>): FetchState<T, U> => ({
  ...state,
  loading: true,
});

const fetchSuccess = <T, U>(
  state: FetchState<T, U>,
  action: FetchAction<T, U>
): FetchState<T, U> => ({
  ...state,
  loading: false,
  error: false,
  responseStatus: action.responseStatus,
  data: (action.payload as T) ?? null,
});

const fetchFailure = <T, U>(
  state: FetchState<T, U>,
  action: FetchAction<T, U>
): FetchState<T, U> => ({
  ...state,
  loading: false,
  error: true,
  responseStatus: action.responseStatus,
  errorData: (action.payload as U) ?? null,
});

const fetchReset = <T, U>(): FetchState<T, U> => getDefaultState<T, U>();

const actionHandlers = {
  [FetchActionTypes.FETCH_INIT]: fetchInit,
  [FetchActionTypes.FETCH_SUCCESS]: fetchSuccess,
  [FetchActionTypes.FETCH_FAILURE]: fetchFailure,
  [FetchActionTypes.FETCH_RESET]: fetchReset,
};

export const dataFetchReducer = <T, U>(
  state: FetchState<T, U>,
  action: FetchAction<T, U>
): FetchState<T, U> => {
  const handler = actionHandlers[action.type] as ActionHandler<T, U>;
  if (!handler) {
    throw new Error(`Unknown action type: ${action.type}`);
  }
  return handler(state, action);
};

export const useFetchReducer = <T, U>(
  initialState?: Partial<FetchState<T, U>>
): [FetchState<T, U>, Dispatch<FetchAction<T, U>>] => {
  const initialMergedState: FetchState<T, U> = {
    ...getDefaultState<T, U>(),
    ...initialState,
  } as FetchState<T, U>;

  return useReducer<Reducer<FetchState<T, U>, FetchAction<T, U>>>(
    dataFetchReducer,
    initialMergedState
  );
};
